<template>
  <span>{{ toDisplay }}</span>
</template>

<script>
import moment from "moment";
import {mapState} from "vuex";

export default {
  name: "AutoRefreshClock",
  props: {
    refreshDaily: {
      type: Boolean,
      default: false
    },
    refreshEveryMinute: {
      type: Boolean,
      default: false
    },
    format: {
      type: String
    }
  },
  data: () => ({
    toDisplay: null
  }),
  computed: {
    ...mapState(['currentLocale'])
  },
  watch: {
    'currentLocale'() {
      this.refreshTimer();
    },
    refreshDaily() {
      this.refreshTimer();
    },
    refreshEveryMinute() {
      this.refreshTimer();
    },
    format() {
      this.refreshTimer();
    },
  },
  mounted() {
    this.refreshTimer();
  },
  methods: {
    refreshTimer() {
      this.toDisplay = moment().format(this.format);
      setTimeout(() => this.refreshTimer(), this.computeNextTickDelay());
    },
    computeNextTickDelay() {
      let now = new Date();
      let nextTick = new Date();
      if (this.refreshDaily) {
        nextTick.setHours(0)
        nextTick.setMinutes(0)
        nextTick.setSeconds(0)
        nextTick.setDate(now.getDate() + 1); // Fera automatiquement passer au mois / à l'année suivante si nécessaire
      } else {
        nextTick.setMinutes(now.getMinutes() + 1)
        nextTick.setSeconds(0)
      }
      return nextTick.getTime() - now.getTime();
    }
  }
}
</script>

<style scoped>

</style>
