import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/animate.css'
import FlagIcon from 'vue-flag-icon'
import VueTimer from 'vue-timers'
import {loadFonts} from './plugins/webfontloader'
import i18n from "@/plugins/i18n";
import easyConfig from "@/plugins/easy-config";

loadFonts()

createApp(App)
  .use(store)
  .use(router)
  .use(FlagIcon)
  .use(VueTimer)
  .use(i18n, {store})
  .use(easyConfig, {store})
  .mount('#app')
