<template>
  <div class="y-table-row-cell">
    <span v-if="labelled" class="y-table-row-label y-row-sm-txt">{{ buy ? $t('weBuyScroll') : $t('weSellScroll')}} </span>

    <!--Vue groupée simple-->
    <div v-if="grouped" class="t-table-row-cell-two-line">
      <span class="y-align-right" v-if="displayType === 'per-base-currency'">{{ format(valueInForeignCurrency) }} / {{ $c('text.global.baseCurrencySymbol') }}</span>
      <span class="y-align-right" v-if="displayType === 'per-base-currency'">{{ format(valueInBaseCurrency) }} {{ $c('text.global.baseCurrencySymbol') }}</span>

      <span class="y-align-right" v-if="displayType === 'one-base-currency-unit-equals'">1{{$c('text.global.baseCurrencySymbol')}} = {{ format(row.base / valueInForeignCurrency) }} {{ row.currencyIso }}</span>
      <span class="y-align-right" v-if="displayType === 'one-base-currency-unit-equals'">{{row.base}} {{ row.currencyIso }} = {{ format(valueInBaseCurrency) }}{{ $c('text.global.baseCurrencySymbol') }}</span>
    </div>

    <!--Vue non groupée-->
    <div v-else>
      <span class="y-align-right" v-if="price && displayType === 'per-base-currency'">{{ format(valueInBaseCurrency) }} {{ $c('text.global.baseCurrencySymbol') }}</span>
      <span class="y-align-right" v-else-if="!price && displayType === 'per-base-currency'">{{ format(valueInForeignCurrency) }} / {{ $c('text.global.baseCurrencySymbol') }}</span>

      <span class="y-align-right" v-if="price && displayType === 'one-base-currency-unit-equals'">1{{$c('text.global.baseCurrencySymbol')}} = {{ format(row.base / valueInForeignCurrency) }} {{ row.currencyIso }}</span>
      <span class="y-align-right" v-else-if="!price && displayType === 'one-base-currency-unit-equals'">{{row.base}} {{ row.currencyIso }} = {{ format(valueInBaseCurrency) }}{{ $c('text.global.baseCurrencySymbol') }}</span>
    </div>

    <!--    <span v-if="labelled" class="y-table-row-label y-row-sm-txt">{{ buy ? 'Nous achetons' : 'Nous vendons'}} </span>-->
    <!--    <span>-->
    <!--      <span class="y-row-sm-txt">1{{$c('baseCurrencySymbol')}} = </span>-->
    <!--      <span>{{ format(row.base / valueInForeignCurrency) }} <span class="y-row-sm-txt">{{ row.iso_devise }}</span></span>-->
    <!--    </span>-->
    <!--    <span v-if="!dense" class="y-table-row-cell-2nd-line y-row-sm-txt">{{row.base}} {{ row.iso_devise }} = {{ format(valueInBaseCurrency) }}{{$c('baseCurrencySymbol')}}</span>-->
  </div>
</template>

<script>


import {mapState} from "vuex";
import {formatRate} from "@/business/formatter";

export default {
  name: "DisplayCurrencyScrollElementValueCell",
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    main: {
      type: Boolean,
      default: false
    },
    labelled: {
      type: Boolean,
      default: false
    },
    grouped: {
      type: Boolean,
      default: false
    },
    rate: {
      type: Boolean,
      default: false
    },
    price: {
      type: Boolean,
      default: false
    },
    displayType: {},
    row: Object,
    buy: Boolean,
    sell: Boolean,
  },
  computed: {
    ...mapState(['config']),
    type() {
      return this.main ? 'main' : 'scroll';
    },
    isBuy() {
      return this.buy === true;
    },
    valueInForeignCurrency() {
      return this.buy ? this.row.buyRate : this.row.sellRate;
    },
    valueInBaseCurrency() {
      return this.buy ? this.row.buyPrice : this.row.sellPrice;
    }
  },
  methods: {
    format(num) {
      return formatRate(num, this.config.currentLocale);
    }
  }
}
</script>

<style scoped>
.y-table-row-cell {
  display: flex;
  align-items: center;
  width: 100%;
  padding: .2em;
}

.y-table-row-cell div {
  width: 100%;
}

.y-table-row-cell .y-table-row-label {
  margin-right: 1em;
}

.t-table-row-cell-two-line {
  display: flex;
  flex-direction: column;
}

.t-table-row-cell-two-line span {
  margin: -.2em 0;
}

.y-align-right {
  display: block;
  text-align: right;
}
</style>
