<template>
  <n-space vertical>
    <h2 id="creator-theme">Thème graphique</h2>
    <h3 id="creator-theme-colors">Couleurs</h3>

    <h4>Assistant de choix de couleurs</h4>
    <p>Pour vous assister dans le choix des couleurs de votre panneau d'affichage, plusieurs options s'offrent à vous.</p>
    <p>En choisissant "Gérer automatiquement les couleurs", vous pouvez facilement appliquer un thème de couleur cohérent à votre panneau en quelques clics :</p>
    <ul>
      <li>L'option "Haut contraste" permet d'accentuer la lisibilité du texte, utile si votre écran d'affichage est exposé au soleil par exemple.</li>
      <li>Par défaut, le texte est plus foncé que la couleur de fond. L'option "Thème sombre" applique un texte clair et choisi un fond sombre.</li>
      <li>Une palette de couleur est automatiquement créée à partir de la couleur principale sélectionnée, et appliquée à la majorité des éléments de votre panneau.</li>
      <li>Par défaut, la couleur du texte et autres petits éléments utilisent une couleur complémentaire calculée à partir de la couleur principale. L'option "Utiliser une couleur secondaire" permet de choisir manuellement cette couleur.</li>
    </ul>
    <p>Pour pousser la personnalisation de votre panneau encore plus loin, il vous est possible de modifier chacun des éléments indépendamment en sélectionnant "Choisir manuellement les couleurs", qui fera apparaitre un nouvel espace "Couleurs par élément" en dessous des couleurs principale et secondaire.</p>
    <p>Lors de cette bascule en mode manuel, vous conservez toutes les couleurs déjà appliquées par le mode automatique.</p>
    <p>Pour assurer une cohérence entre les couleurs que vous choisissez pour votre panneau, des couleurs calculées depuis vos couleurs principale et secondaire vous serons proposées pour chaque élément personnalisable. Si vous souhaitez modifier les couleurs proposées, vous pouvez modifier votre couleur principale et secondaire, cela n'affectera pas les couleurs de votre panneau tant que vous êtes en mode manuel.</p>
    <p>Attention, rebasculer sur l'option "Gérer automatiquement les couleurs" appliquera de nouveau la palette de couleur automatique, et peut donc écraser vos modifications.</p>

    <n-radio-group v-model:value="colorMode">
      <n-radio label="Gérer automatiquement les couleurs" value="auto"/>
      <n-radio label="Choisir manuellement les couleurs" value="manual"/>
    </n-radio-group>

    <n-space v-if="colorMode==='auto'">
      <n-checkbox label="Haut contraste" v-model:checked="highContrastMode"/>
      <n-checkbox label="Thème sombre" v-model:checked="darkMode"/>
    </n-space>
    <h4 id="creator-theme-colors-principal">Couleur principale</h4>
    <n-color-picker v-model:value="themeColor" :show-alpha="false" :modes="['hex']"/>
    <h4 id="creator-theme-colors-secondary">Couleur secondaire</h4>
    <n-checkbox v-model:checked="useSecondColor" label="Utiliser une couleur secondaire"/>
    <n-color-picker v-if="useSecondColor" v-model:value="secondColor" :show-alpha="false" :modes="['hex']"/>

    <div v-if="colorMode==='manual'">
      <h4>Couleurs par élément</h4>
      <n-card>
        <n-tabs type="line" :tabs-padding="20">
          <n-tab-pane name="default" label="Par défaut">
            <theme-color-element color-key="default.text" :swatches="swatches" label="Texte par défaut"/>
            <theme-color-element color-key="default.background" :swatches="swatches" label="Fond par défaut"/>
          </n-tab-pane>
          <n-tab-pane name="topbar" label="Titre">
            <theme-color-element color-key="topBar.text" :swatches="swatches" has-default label="Texte barre de titre"/>
            <theme-color-element color-key="topBar.background" :swatches="swatches" has-default
                                 label="Fond barre de titre"/>
          </n-tab-pane>
          <n-tab-pane name="main" label="Tableau fixe">
            <theme-color-element color-key="table.main.lines.even.text" :swatches="swatches" has-default
                                 label="Texte tableau fixe (lignes paires)"/>
            <theme-color-element color-key="table.main.lines.even.background" :swatches="swatches" has-default
                                 label="Fond tableau fixe (lignes paires)"/>
            <theme-color-element color-key="table.main.lines.odd.text" :swatches="swatches" has-default
                                 label="Texte tableau fixe (lignes impaires)"/>
            <theme-color-element color-key="table.main.lines.odd.background" :swatches="swatches" has-default
                                 label="Fond tableau fixe (lignes impaires)"/>
            <theme-color-element color-key="table.main.lines.border" :swatches="swatches" label="Bordure tableau fixe"/>
          </n-tab-pane>
          <n-tab-pane name="scroll" label="Tableau défilant">
            <theme-color-element color-key="table.scroll.lines.even.text" :swatches="swatches" has-default
                                 label="Texte tableau défilant (lignes paires)"/>
            <theme-color-element color-key="table.scroll.lines.even.background" :swatches="swatches" has-default
                                 label="Fond tableau défilant (lignes paires)"/>
            <theme-color-element color-key="table.scroll.lines.odd.text" :swatches="swatches" has-default
                                 label="Texte tableau défilant (lignes impaires)"/>
            <theme-color-element color-key="table.scroll.lines.odd.background" :swatches="swatches" has-default
                                 label="Fond tableau défilant (lignes impaires)"/>
            <theme-color-element color-key="table.scroll.lines.border" :swatches="swatches"
                                 label="Bordure tableau défilant"/>
          </n-tab-pane>
          <n-tab-pane name="footer" label="Pied de page">
            <theme-color-element color-key="footer.text" :swatches="swatches" has-default label="Texte pied de page"/>
            <theme-color-element color-key="footer.background" :swatches="swatches" has-default
                                 label="Fond pied de page"/>
          </n-tab-pane>
          <n-tab-pane name="jumbotron" label="Messages">
            <theme-color-element color-key="jumbotron.text" :swatches="swatches" has-default
                                 label="Texte panneau de messages intermittents"/>
            <theme-color-element color-key="jumbotron.background" :swatches="swatches"
                                 label="Fond panneau de messages intermittents"/>
          </n-tab-pane>
        </n-tabs>
      </n-card>
    </div>

    <h3 id="creator-theme-flag-library">Style de drapeaux</h3>
    <n-select :options="availableFlagLibraries" v-model:value="flagLibrary"/>

    <h3 id="creator-theme-font">Police de caractère</h3>
    <n-select :options="availableFonts" v-model:value="fontType"/>
    <h4>Épaisseur de la police de caractère</h4>
    <p>L'effet de ce réglage peut varier suivant la police de caractère sélectionnée.</p>
    <n-slider :min="100" :max="900" :step="100" :tooltip="false" v-model:value="fontWeight"/>

    <h3 id="creator-theme-sizes">Taille des éléments</h3>
    <h4>Global</h4>
    <n-slider v-model:value="sizes.global" :min="12" :max="48" :step=".1" :tooltip="false" @mouseup="triggerResizeEvent"/>
    <h4>Barre de titre</h4>
    <n-slider v-model:value="sizes.relativeTopBar" :min=".5" :max="2" :step=".01" :tooltip="false" @mouseup="triggerResizeEvent"/>
    <h4>Lignes fixes</h4>
    <h5>Agrandissement</h5>
    <n-slider v-model:value="sizes.relativeMainTable" :min="1" :max="3" :step=".01" :tooltip="false" @mouseup="triggerResizeEvent"/>
    <h5>Drapeaux</h5>
    <n-slider v-model:value="sizes.relativeMainFlags" :min="1" :max="3" :step=".01" :tooltip="false" @mouseup="triggerResizeEvent"/>
    <h4>Lignes défilantes</h4>
    <h5>Drapeaux</h5>
    <n-slider v-model:value="sizes.relativeScrollFlags" :min="1" :max="3" :step=".01" :tooltip="false" @mouseup="triggerResizeEvent"/>
    <h4>Pied de page</h4>
    <n-slider v-model:value="sizes.relativeFooter" :min="1" :max="2" :step=".01" :tooltip="false" @mouseup="triggerResizeEvent"/>
  </n-space>
</template>

<script>

import {NCard, NCheckbox, NColorPicker, NRadio, NRadioGroup, NSelect, NSlider, NSpace, NTabPane, NTabs} from 'naive-ui'
import {buildColorPalette, buildTheme} from "@/business/theme-maker";
import ThemeColorElement from "@/components/creator/ThemeColorElement";

export default {
  name: "ThemeEditor",
  components: {
    ThemeColorElement,
    NSpace,
    NCheckbox,
    NColorPicker,
    NSelect,
    NSlider,
    NRadioGroup,
    NRadio,
    NTabs,
    NTabPane,
    NCard
  },
  data: () => ({
    useSecondColor: false,
    themeColor: '#CCCCCC',
    secondColor: '#CCCCCC',
    fontType: null,
    fontWeight: null,
    flagLibrary: null,

    colorMode: 'auto',
    darkMode: false,
    highContrastMode: false,

    sizes: {
      global: null,
      relativeMainSize: null,
      relativeMainFlags: null,
      relativeScrollFlags: null,
      relativeTopBar: null,
      relativeFooter: null,
    },

    availableFonts: [
      {label: 'Classique - Arial', value: 'Arial'},
      {label: 'Classique - Arial Condensé', value: 'Arial Narrow'},
      {label: 'Classique - Verdana', value: 'Verdana'},
      {label: 'Moderne - Josefin', value: 'Josefin Sans'},
      {label: 'Moderne - Roboto', value: 'Roboto'},
      {label: 'Moderne - Roboto Condensé', value: 'Roboto Condensed'},
      {label: 'Moderne - Albert', value: 'Albert Sans'},
      {label: 'Moderne - Manrope', value: 'Manrope'},
      {label: 'Stylisé - Edu QLD Beginner', value: 'Edu QLD Beginner'},
      {label: 'Stylisé - Permanent Marker', value: 'Permanent Marker'},
      {label: 'Stylisé - Chakra Petch', value: 'Chakra Petch'},
      {label: 'Stylise - Koulen', value: 'Koulen'},
      {label: 'Retro - Times New Roman', value: 'Times New Roman'},
      {label: 'Retro - Comic', value: '"Comic Neue"'}
    ],

    availableFlagLibraries: [
      {label: 'Fidèle', value: 'flat'},
      {label: 'Glossy', value: 'embossed'},
      {label: 'Carré 3D', value: 'square-3d'},
      {label: 'Voyage', value: 'suitcase'},
      {label: 'Drapeau 3D', value: 'waving-flag'},
    ]
  }),
  mounted() {
    // XXX Rajouter les couleurs

    this.fontType = this.$store.getters.getConfigKey('theme.font.type');
    this.fontWeight = this.$store.getters.getConfigKey('theme.font.weight.default');

    this.flagLibrary = this.$store.getters.getConfigKey('theme.assets.flagLibrary');

    this.themeColor = this.$store.getters.getConfigKey('theme.colors.palette.primary');
    this.secondColor = this.$store.getters.getConfigKey('theme.colors.palette.secondary');
    this.useSecondColor = this.$store.getters.getConfigKey('theme.colors.palette.useSecondary');

    this.highContrastMode = this.$store.getters.getConfigKey('theme.colors.palette.autoStyle.highContrast');
    this.darkMode = this.$store.getters.getConfigKey('theme.colors.palette.autoStyle.dark');

    this.sizes.global = Number.parseFloat(this.$store.getters.getConfigKey('theme.sizes.global').replace(/\D/g, '')); // On enlève l'unit de mesure
    this.sizes.relativeMainTable = Number.parseFloat(this.$store.getters.getConfigKey('theme.sizes.relativeMainTable').replace(/\D/g, '')); // On enlève l'unit de mesure
    this.sizes.relativeMainFlags = Number.parseFloat(this.$store.getters.getConfigKey('theme.sizes.relativeMainFlags').replace(/\D/g, '')); // On enlève l'unit de mesure
    this.sizes.relativeScrollFlags = Number.parseFloat(this.$store.getters.getConfigKey('theme.sizes.relativeScrollFlags').replace(/\D/g, '')); // On enlève l'unit de mesure
    this.sizes.relativeTopBar = Number.parseFloat(this.$store.getters.getConfigKey('theme.sizes.relativeTopBar').replace(/\D/g, '')); // On enlève l'unit de mesure
    this.sizes.relativeFooter = Number.parseFloat(this.$store.getters.getConfigKey('theme.sizes.relativeFooter').replace(/\D/g, '')); // On enlève l'unit de mesure

    for (const font of this.availableFonts) {
      font.style = {
        'font-family': font.value
      }
    }
  },
  computed: {
    swatches() {
      let palette = this.$store.getters.getConfigKey('theme.colors.palette.computed');
      let r = ['#FFFFFF', '#EEEEEE', '#CCCCCC', '#AFAFAF', '#999999', '#666666', '#333333', '#000000'];
      Object.keys(palette).forEach((k) => {
        Object.values(palette[k]).forEach(v => r.push(v));
      })
      return r;
    }
  },
  watch: {
    fontType(v) {
      this.$store.commit('setConfigKey', {key: 'theme.font.type', value: v});
    },
    fontWeight(v) {
      this.$store.commit('setConfigKey', {key: 'theme.font.weight.default', value: v});
    },
    flagLibrary(v) {
      this.$store.commit('setConfigKey', {key: 'theme.assets.flagLibrary', value: v});
    },
    'sizes.global'(v) {
      this.$store.commit('setConfigKey', {key: 'theme.sizes.global', value: v + 'px'});
    },
    'sizes.relativeMainTable'(v) {
      this.$store.commit('setConfigKey', {key: 'theme.sizes.relativeMainTable', value: v + 'em'});
    },
    'sizes.relativeMainFlags'(v) {
      this.$store.commit('setConfigKey', {key: 'theme.sizes.relativeMainFlags', value: v + 'em'});
    },
    'sizes.relativeScrollFlags'(v) {
      this.$store.commit('setConfigKey', {key: 'theme.sizes.relativeScrollFlags', value: v + 'em'});
    },
    'sizes.relativeTopBar'(v) {
      this.$store.commit('setConfigKey', {key: 'theme.sizes.relativeTopBar', value: v + 'em'});
    },
    'sizes.relativeFooter'(v) {
      this.$store.commit('setConfigKey', {key: 'theme.sizes.relativeFooter', value: v + 'em'});
    },

    themeColor(v) {
      let colorPalette = this.useSecondColor ? buildColorPalette(v, this.secondColor) : buildColorPalette(v);
      this.$store.commit('setConfigKey', {key: 'theme.colors.palette.primary', value: v});
      this.$store.commit('setConfigKey', {key: 'theme.colors.palette.computed', value: colorPalette});
      if (this.colorMode === 'auto') {
        this.$store.commit('setConfigKey', {key: 'theme.colors.elements', value: buildTheme(colorPalette, this.highContrastMode, this.darkMode)});
      }
    },
    secondColor(v) {
      this.$store.commit('setConfigKey', {key: 'theme.colors.palette.secondary', value: v});
      if (this.useSecondColor) {
        let colorPalette = buildColorPalette(this.themeColor, v);
        this.$store.commit('setConfigKey', {key: 'theme.colors.palette.computed', value: colorPalette});
        if (this.colorMode === 'auto') {
          this.$store.commit('setConfigKey', {key: 'theme.colors.elements', value: buildTheme(colorPalette, this.highContrastMode, this.darkMode)});
        }
      }
    },
    useSecondColor(v) {
      this.$store.commit('setConfigKey', {key: 'theme.colors.palette.useSecondary', value: v});
      let colorPalette = v ? buildColorPalette(this.themeColor, this.secondColor) : buildColorPalette(this.themeColor);
      this.$store.commit('setConfigKey', {key: 'theme.colors.palette.computed', value: colorPalette});
      if (this.colorMode === 'auto') {
        this.$store.commit('setConfigKey', {key: 'theme.colors.elements', value: buildTheme(colorPalette, this.highContrastMode, this.darkMode)});
      }
    },
    darkMode(v) {
      this.$store.commit('setConfigKey', {key: 'theme.colors.palette.autoStyle.dark', value: v});
      if (this.colorMode === 'auto') {
        this.$store.commit('setConfigKey', {key: 'theme.colors.elements', value: buildTheme(this.$store.getters.getConfigKey('theme.colors.palette.computed'), this.highContrastMode, v)});
      }
    },
    highContrastMode(v) {
      this.$store.commit('setConfigKey', {key: 'theme.colors.palette.autoStyle.highContrast', value: v});
      if (this.colorMode === 'auto') {
        this.$store.commit('setConfigKey', {key: 'theme.colors.elements', value: buildTheme(this.$store.getters.getConfigKey('theme.colors.palette.computed'), v, this.darkMode)});
      }
    }
  },
  methods: {
    // Devrait être bindé au @change des inputs qui changent des tailles d'éléments dans la page
    // Le @mouseup est plus pratique que les watchers car il est déclenché une fois que le slider est relâché, niveau perf c'est beaucoup mieux
    triggerResizeEvent() {
      window.dispatchEvent(new Event('resize'));
    }
  }
}
</script>

<style scoped>

</style>
