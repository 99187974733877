<template>
  <footer>
    <span>{{ $t('footer') }} {{ dateToDisplay }}</span>
  </footer>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";

export default {
  name: "DisplayFooter",
  computed: {...mapState(['data', 'config', 'currentLocale'])},
  data: () => ({
    dateToDisplay: null
  }),
  mounted() {
    this.refreshDate();
  },
  watch: {
    'currentLocale'() {
      this.refreshDate();
    },
    'data.updatedAt'(v) {
      this.refreshDate(v);
    }
  },
  methods: {
    refreshDate(d) {
      this.dateToDisplay = moment(d ? d : this.data.updatedAt).fromNow()
    }
  }
}
</script>

<style scoped>
footer {
  font-size: v-bind('config.theme.sizes.relativeFooter');
  color: v-bind('config.theme.colors.elements.footer.text');
  background-color: v-bind('config.theme.colors.elements.footer.background');
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
