<template>
  <div style="position: relative;">

    <n-icon v-if="connectionStatus !== dataBusiness.connectionStatus.CONNECTED && autoFetchData" class="y-disconnected-icon" size="15"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><path d="M41.37 64l22.628-22.628L470.627 448l-22.628 22.627z" fill="currentColor"/><path d="M38.72 212.78C13.39 235.88 0 267.42 0 304c0 36 14.38 68.88 40.49 92.59C65.64 419.43 99.56 432 136 432h228.12L110.51 178.39c-28.01 5.39-53.09 17.33-71.79 34.39z" fill="currentColor"/><path d="M476.59 407.23C499.76 388.78 512 361.39 512 328c0-61.85-48.44-95.34-97.75-102.64c-6.52-41.18-24.05-76.4-51.11-102.46A153.57 153.57 0 0 0 256 80c-30.47 0-58.9 8.62-83.07 25l302.82 302.86c.25-.21.57-.41.84-.63z" fill="currentColor"/></svg></n-icon>
    <div v-if="connectionStatus === dataBusiness.connectionStatus.VIOLATION_ERROR" class="y-violation-message">
      <span>Vous avez atteint le nombre d'affichage simultané maximum pour ce panneau d'affichage.<br/><br/>Nouvelle tentative de connexion quelques secondes...</span>
    </div>

    <div class="y-main-container" v-if="loaded">
      <display-title class="y-title-bar"/>
      <div class="y-data-container">
        <display-currency-main-table/>
        <div style="height: 100%; position: relative;">
          <div class="y-data-container-shadow-box"/>
          <display-currency-scroll-horizontal v-if="$c('theme.structure.scrollingDirection') === 'horizontal'" :scroll="!showJumbotron"/>
          <display-currency-scroll-table v-else :scroll="!showJumbotron"/>
          <display-jumbotron :show="showJumbotron"/>
        </div>
      </div>
      <display-footer/>
    </div>
    <span v-else-if="error">Une erreur est survenue</span>
    <div v-else>Chargement...</div>
  </div>
</template>

<script>

import DisplayTitle from "@/components/displayparts/DisplayTitle";
import DisplayCurrencyMainTable from "@/components/displayparts/currency/DisplayCurrencyMainTable.vue";
import DisplayCurrencyScrollTable from "@/components/displayparts/currency/DisplayCurrencyScrollTable.vue";
import DisplayCurrencyScrollHorizontal from "@/components/displayparts/currency/DisplayCurrencyScrollHorizontal.vue";
import DisplayFooter from "@/components/displayparts/DisplayFooter";
import DisplayJumbotron from "@/components/displayparts/DisplayJumbotron";
import {mapActions, mapState} from "vuex";
import {NIcon} from "naive-ui"
import dataBusiness from "@/business/data-business";

export default {
  name: 'BaseDisplayLayout',
  components: {
    DisplayCurrencyScrollHorizontal,
    DisplayCurrencyScrollTable,
    DisplayJumbotron, DisplayFooter, DisplayCurrencyMainTable, DisplayTitle,
    NIcon
  },
  data: () => ({
    showSequencedJumbotron: false,
    jumbotronTimer: null,
    languageTimer: null,
  }),
  props: {
    autoFetchData: {
      type: Boolean,
      default: true
    },
    forceJumbotron: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dataBusiness() {
      return dataBusiness
    },
    ...mapState(['data', 'error', 'loaded', 'config', 'currentLocale', 'connectionStatus']),
    showJumbotron() {
      return this.forceJumbotron || this.showSequencedJumbotron;
    }
  },
  watch: {
    'config.timers.jumbotron.interval'(v) {
      if (v > 0) this.jumbotronLoop();
      else clearTimeout(this.jumbotronTimer);
    },
    'config.timers.languageSwitch'(v) {
      clearTimeout(this.languageTimer);
      if (v > 0) this.languageLoop();
    }
  },
  mounted() {
    if (this.config.timers.jumbotron.interval) {
      setTimeout(() => this.jumbotronLoop(), this.config.timers.jumbotron.interval);
    }
    this.languageLoop();

    if (this.autoFetchData) {
      this.subscribeToDataSource();
    } else {
      this.fetchDataOnce();
    }

  },
  methods: {
    ...mapActions(['subscribeToDataSource', 'fetchDataOnce']),
    jumbotronLoop() {
      this.showSequencedJumbotron = true;
      setTimeout(() => {
        this.showSequencedJumbotron = false;
        if (this.config.timers.jumbotron.interval > 0) {
          // On fait plusieurs setTimeout plutôt qu'un setInterval pour pouvoir modifier la valeur de l'intervalle
          this.jumbotronTimer = setTimeout(() => this.jumbotronLoop(), this.config.timers.jumbotron.interval);
        }
      }, this.config.timers.jumbotron.displayTime);
    },
    languageLoop() {
      this.languageTimer = setTimeout(() => {
        let languages = Object.keys(this.config.text.languages);
        let curIdx = languages.indexOf(this.currentLocale);
        let nextIdx = (curIdx >= languages.length - 1) ? 0 : curIdx + 1;
        this.$store.commit('setCurrentLocale', languages[nextIdx]);
        if (this.config.timers.languageSwitch > 0) this.languageLoop();
      }, this.config.timers.languageSwitch);
    }
  }
}
</script>

<style>

.y-disconnected-icon {
  position: absolute;
  z-index: 10;
  bottom: 5px;
  left: 5px;
  filter: drop-shadow(0 0 1px white);
}

/** Conteneurs et mise en page */
.y-main-container * {
  box-sizing: border-box;
  text-align: center;
  font-family: v-bind('config.theme.font.type');
  line-height: 1.6;
}

.y-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: v-bind('config.theme.colors.elements.default.text');
  background-color: v-bind('config.theme.colors.elements.default.background');

  /*font-variant-caps: all-small-caps;*/

  /*font-family: 'Arial Narrow';*/
  font-size: v-bind('config.theme.sizes.global');
  font-weight: v-bind('config.theme.font.weight.default');
}

.y-title-bar {
  width: 100%;
  height: 8em;
}

.y-data-container {
  /** La data (fixe et défilante) prendra toute la hauteur SAUF la title-bar et le footer
   XXX : le footer bouge si la top bar est trop petite, fixé un peu via l'utilisation de "rem" pour la hauteur de la top bar**/
  height: calc(100% - 8rem - 2em);

  background-color: v-bind('config.theme.colors.elements.background');
  display: flex;
  flex-direction: column;
}

.y-main-container footer {
  width: 100%;
  height: 2em;
}

.y-display-jumbotron-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}


/** Lignes des cours (commun) **/

.y-data-container .y-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.y-data-container .y-data-common .y-table-row .y-cell-iso {
  min-width: 4em;
}

.y-data-container .y-data-common .y-table-row .y-cell-flag {
  min-width: 4em;
}

.y-data-container .y-data-common .y-table-row .y-cell-country {
  text-align: left;
  padding: 0 .3em;
  flex-grow: 1;
}

.y-data-container .y-data-common .y-table-row .y-cell-currency {
  flex-grow: 0;
  min-width: 8em;
  width: 16%;
}

.y-data-container .y-data-common .y-table-row .y-cell-buy-or-sell.y-cell-buy-or-sell-double {
  width: 14em;
}


.y-data-container .y-data-common .y-table-row .y-cell-buy-or-sell {
  width: 7em; /** XXX Rendre paramétrable **/
  flex: 0 0 auto;
  white-space: nowrap;
  overflow: visible;
}

.y-data-container .y-table-row .y-flag {
  display: flex;
  align-items: center;
}

.y-data-container .y-data-main .y-flag span {
  font-size: v-bind('config.theme.sizes.relativeMainFlags');
}

.y-data-container .y-data-table-scroll .y-flag span {
  font-size: v-bind('config.theme.sizes.relativeScrollFlags');
}

.y-data-container .y-data-horizontal-main .y-flag span {
  font-size: v-bind('config.theme.sizes.relativeScrollFlags');
}

.y-data-container .y-data-container-shadow-box {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  /** Ombre juste en haut et en bas **/
  box-shadow: inset 0 1em 1em -1em rgba(0, 0, 0, 0.5), inset 0 -1em 1em -1em rgba(0, 0, 0, 0.5);
}

.y-violation-message {
  position: fixed;
  width: 50vw;
  height: 50vh;
  left: 25vw;
  top: 25vh;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 1em;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 1em;
  font-size: 2em;
  text-align: center;
}

</style>
