export function yodaforexTimestampToDate(ts) {
  let date = new Date();
  date.setFullYear(ts.substring(0, 4))
  date.setMonth(Number.parseInt(ts.substring(4, 6)) - 1) // Parce qu'on parle de l'index du mois (donc janvier = 0, février = 1)
  date.setDate(ts.substring(6, 8))
  date.setHours(ts.substring(8, 10))
  date.setMinutes(ts.substring(10, 12))
  date.setSeconds(ts.substring(12, 14))
  return date;
}

const FORMATTERS = {};

function getFormatter(locale) {
  if (FORMATTERS[locale]) {
    return FORMATTERS[locale];
  } else {
    let newFormatter = new Intl.NumberFormat(locale, {minimumSignificantDigits: 5, maximumSignificantDigits: 5});
    FORMATTERS[locale] = newFormatter;
    return newFormatter;
  }
}

export function formatRate(num, locale) {
  return getFormatter(locale).format(num);
}
