import {createRouter, createWebHistory} from 'vue-router'
import DisplayView from '../views/DisplayView.vue'
import CreatorView from "@/views/CreatorView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: DisplayView
  },
  {
    path: '/creator',
    name: 'creatorView',
    component: CreatorView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
