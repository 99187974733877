export function getAllTextKeys() {
  // XXX recevoir la locale de l'interface créateur pour dynamiser les labels
  return languagesKeys;
}

const languagesKeys = [
  {label: 'Titre', key: 'title'},
  {label: 'Partie fixe — Entête "achat"', key: 'weBuyMain'},
  {label: 'Partie fixe — Entête "vente"', key: 'weSellMain'},
  {label: 'Partie défilante — Entête "achat"', key: 'weBuyScroll'},
  {label: 'Partie défilante — Entête "vente"', key: 'weSellScroll'},
  {label: 'Pied de page', key: 'footer'},
]
