<template>
  <div class="preview-panel"
       :style="'--y-preview-scale:'+previewScale+';--y-preview-width:'+previewWidth+'px;--y-preview-height:'+previewHeight+'px;'">
    <n-space justify="center" class="y-preview-options-form">
      <n-select :options="previewSizingOptions" v-model:value="selectedPreviewSizingPreset"
                :consistent-menu-width="false"/>
      <n-checkbox v-model:checked="previewHorizontal" v-if="selectedPreviewSizingPreset!==''" label="Horizontal"/>
      <!--      <n-input-number v-model:value="previewHeight" :disabled="selectedPreviewSizingPreset!==''"-->
      <!--                      placeholder="Hauteur" :min="1" :max="10000"/>-->
      <!--      <n-input-number v-model:value="previewWidth" :disabled="selectedPreviewSizingPreset!==''"-->
      <!--                      placeholder="Largeur" :min="1" :max="10000"/>-->
    </n-space>
    <base-display-layout class="preview-container" :auto-fetch-data="false"/>
  </div>
</template>

<script>
import {NCheckbox, NSelect, NSpace,} from 'naive-ui'
import BaseDisplayLayout from "@/components/layouts/BaseCurrencyLayout";

export default {
  name: "EditorPreview",
  components: {
    BaseDisplayLayout,
    NSpace,
    // NInputNumber,
    NCheckbox,
    NSelect,
  },
  data: () => ({
    previewScale: .5,
    previewWidth: 1080,
    previewHeight: 1920,
    previewHorizontal: false,
    selectedPreviewSizingPreset: '1920:1080',

    previewSizingOptions: [ // Toujours mettre la dimension la plus grande est toujours en première pour pouvoir s'y retrouver lors du switch horizontal/vertical
      {label: 'Full HD (1080p)', value: '1920:1080'},
      {label: 'HD Ready (720p)', value: '1280:720'},
      {label: '4K', value: '3840:2160'},
      {label: 'Apple iPad', value: '1080:810'},
      {label: 'Apple iPad Air', value: '1080:820'},
      {label: 'Apple iPad Pro 11"', value: '1194:834'},
      {label: 'Apple iPad Pro 12,9"', value: '1366:1080'},
      {label: 'Apple iPad Mini', value: '1133:744'},
      {label: 'Microsoft Surface Pro 7', value: '1368:912'},
      // {label: 'Dimensions libres', value: ''}, // Buggy, on regardera plus tard
    ],
  }),
  mounted() {
    this.computePreviewScale();
    window.addEventListener("resize", this.computePreviewScale);
  },
  unmounted() {
    window.removeEventListener("resize", this.computePreviewScale);
  },
  watch: {
    selectedPreviewSizingPreset(v) {
      if (v !== '') {
        let split = v.split(':'); // La dimension la plus grande est toujours en première
        this.previewWidth = Number.parseInt(split[this.previewHorizontal ? 0 : 1]);
        this.previewHeight = Number.parseInt(split[this.previewHorizontal ? 1 : 0]);
      }
    },
    previewHorizontal(v) {
      let split = this.selectedPreviewSizingPreset.split(':'); // La dimension la plus grande est toujours en première
      this.previewWidth = Number.parseInt(split[v ? 0 : 1]);
      this.previewHeight = Number.parseInt(split[v ? 1 : 0]);
    },
    previewWidth() {
      this.$nextTick(() => this.computePreviewScale()); // $nextTick comme ça la nouvelle valeur sera déjà assignée à this.previewWidth et computePreviewScale() pourra récup la nouvelle valeur sans qu'on ait à lui donner
    },
    previewHeight() {
      this.$nextTick(() => {
        this.computePreviewScale(); // $nextTick => voir au dessus

        this.$nextTick(() => { // On previent le display qui avoir besoin de s'adapter ($nextTick à nouveau pour que tout soit bien à la nouvelle taille avant que le display ne s'adapate)
          window.dispatchEvent(new Event('resize'));
        })
      })
    }
  },
  methods: {
    computePreviewScale() {
      let scaleForHeight = (window.innerHeight / this.previewHeight) * .9;
      let scaleForWidth = (window.innerWidth / 2 / this.previewWidth) * .9;
      let smallest = scaleForHeight > scaleForWidth ? scaleForWidth : scaleForHeight;
      this.previewScale = Math.floor(smallest * 100) / 100;
    }
  }

}
</script>

<style>

.preview-panel {
  position: relative;
  display: flex;
  flex-direction: column;
}

.y-preview-options-form {
  display: flex;
  align-items: center;
}

.preview-container > div {

  position: fixed;
  top: min(calc((50vw - (var(--y-preview-width) * var(--y-preview-scale))) / 2), calc((100vh - var(--y-preview-height) * var(--y-preview-scale)) / 2));
  left: calc(50vw + (50vw - (var(--y-preview-width) * var(--y-preview-scale))) / 2);
  transform: scale(var(--y-preview-scale));
  transform-origin: left top;

  width: var(--y-preview-width);
  height: var(--y-preview-height);

  box-shadow: 0 0 1em .3em rgba(0, 0, 0, .2);
  border: 1px solid black;
}
</style>
