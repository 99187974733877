<template>
  <base-display-layout class="fullscreen"
                       v-bind:class="{'y-rotation-90': config.medium.forceRotation === 90, 'y-rotation-180': config.medium.forceRotation === 180, 'y-rotation-270': config.medium.forceRotation === 270}"/>
</template>

<script>
import BaseDisplayLayout from "@/components/layouts/BaseCurrencyLayout";
import {mapState} from "vuex";

export default {
  name: "DisplayView",
  components: {BaseDisplayLayout},
  computed: {
    ...mapState(['config'])
  }
}
</script>

<style>

.fullscreen.y-rotation-180 {
  transform: rotate(180deg);
}

.fullscreen.y-rotation-270, .fullscreen.y-rotation-90 {
  position: absolute;
  height: 100vw;
  width: 100vh;
}

.fullscreen.y-rotation-90 {
  transform: rotate(90deg);
  transform-origin: bottom left;
  top: -100vw;
  left: 0;
}

.fullscreen.y-rotation-270 {
  transform: rotate(270deg);
  transform-origin: bottom right;
  top: -100vw;
  right: 0;
}

.fullscreen {
  position: relative;
  width: 100vw;
  height: 100vh;
}
</style>
