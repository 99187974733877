<template>
  <n-space vertical>
    <n-space vertical v-for="k in keys" :key="language+k">
      <h5>{{ k.label }}</h5>
      <n-input type="text" :value="$c('text.languages.'+language+'.'+k.key)" @input="onInputChange(k.key, $event)"/>
    </n-space>
  </n-space>
</template>

<script>
import {NInput, NSpace} from 'naive-ui'
import {getAllTextKeys} from "@/business/language-helper";

export default {
  name: "LanguageEditorTab",
  components: {NSpace, NInput},
  props: {
    language: String
  },
  data: () => ({
    keys: getAllTextKeys()
  }),
  methods: {
    onInputChange(key, newVal) {
      let configKey = 'text.languages.' + this.language + '.' + key;
      this.$store.commit('setConfigKey', {key: configKey, value: newVal});
    }
  }
}
</script>

<style scoped>

</style>
