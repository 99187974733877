<template>
  <div>
    <div v-if="config.theme.structure.columns[columnType].indexOf('iso')>=0" class="y-cell-iso">{{row.currencyIso.toUpperCase()}}</div>
    <div v-if="config.theme.structure.columns[columnType].indexOf('flag')>=0" class="y-cell-flag y-flag"><country-flag :iso="row.countryIso" :type="config.theme.assets.flagLibrary"/></div>
    <div v-if="config.theme.structure.columns[columnType].indexOf('country')>=0" class="y-cell-country">{{ row.countryDefaultName }}</div>
    <display-currency-scroll-element-currency-cell v-if="config.theme.structure.columns[columnType].indexOf('currency')>=0" :row="row" class="y-cell-currency" :short="short"/>

    <!-- Colonnes groupées -->
    <display-currency-scroll-element-value-cell v-if="config.theme.structure.columns[columnType].indexOf('buy-both')>=0"  :row="row" buy grouped :main="main" :labelled="labelled" :display-type="valueCellDisplayType" class="y-cell-buy-or-sell"/>
    <display-currency-scroll-element-value-cell v-if="config.theme.structure.columns[columnType].indexOf('sell-both')>=0" :row="row" sell grouped :main="main" :labelled="labelled" :display-type="valueCellDisplayType" class="y-cell-buy-or-sell"/>

    <!-- Colonnes distinctes -->
    <display-currency-scroll-element-value-cell v-if="config.theme.structure.columns[columnType].indexOf('buy-price')>=0"  :row="row" buy price :main="main" :labelled="labelled" :display-type="valueCellDisplayType" class="y-cell-buy-or-sell"/>
    <display-currency-scroll-element-value-cell v-if="config.theme.structure.columns[columnType].indexOf('buy-rate')>=0"  :row="row" buy rate :main="main" :labelled="labelled" :display-type="valueCellDisplayType" class="y-cell-buy-or-sell"/>
    <display-currency-scroll-element-value-cell v-if="config.theme.structure.columns[columnType].indexOf('sell-price')>=0" :row="row" sell price :main="main" :labelled="labelled" :display-type="valueCellDisplayType" class="y-cell-buy-or-sell"/>
    <display-currency-scroll-element-value-cell v-if="config.theme.structure.columns[columnType].indexOf('sell-rate')>=0" :row="row" sell rate :main="main" :labelled="labelled" :display-type="valueCellDisplayType" class="y-cell-buy-or-sell"/>
  </div>
</template>

<script>
import DisplayCurrencyScrollElementValueCell from "@/components/displayparts/currency/DisplayCurrencyScrollElementValueCell.vue";
import {mapState} from "vuex";
import DisplayCurrencyScrollElementCurrencyCell from "@/components/displayparts/currency/DisplayCurrencyScrollElementCurrencyCell.vue";
import CountryFlag from "@/components/misc/CountryFlag";

export default {
  name: "DisplayCurrencyScrollElement",
  components: {CountryFlag, DisplayCurrencyScrollElementCurrencyCell, DisplayCurrencyScrollElementValueCell},
  computed: {
    ...mapState(['config']),
    columnType() {
      return this.main ? 'main' : 'scroll';
    },
    valueCellDisplayType() {
      return this.$c('theme.structure.displayType.buyAndSell.' + this.columnType);
    }
  },
  props: {
    row: Object,
    main: {
      type: Boolean,
      default: false
    },
    short: {
      type: Boolean,
      default: false
    },
    labelled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
