<template>
  <div class="y-data-horizontal-main">
    <div class="y-data-horizontal-lines-container" ref="lineContainer">
      <!-- uniquement pour le calcul de la hauteur (on le met au début pour que le selecteur css 'not-last' fonctionne quand même) -->
      <div class="y-data-scroll-horizontal-line-ghost y-data-scroll-line" ref="ghostLine"/>

      <!-- Vraies lignes -->
      <display-currency-scroll-horizontal-line class="y-data-scroll-line" v-for="(l,i) in lines" :key="i" :data="l.data" :range-start="l.rangeStart"
                                      :range-end="l.rangeEnd" :scroll="scroll" :offset-color="i%2===0"/>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import DisplayCurrencyScrollHorizontalLine from "@/components/displayparts/currency/DisplayCurrencyScrollHorizontalLine.vue";

function Line() {
  this.rangeStart = null;
  this.rangeEnd = null;
  this.data = [];
}

export default {
  name: "DisplayCurrencyScrollHorizontal",
  props: {
    scroll: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    numberOfLines: 0
  }),
  components: {DisplayCurrencyScrollHorizontalLine},
  mounted() {
    this.computeLineHeight();
    window.addEventListener("resize", this.computeLineHeight);
    // XXX Ecouter aussi le paramétrage de la taille du texte dans la config
  },
  unmounted() {
    window.removeEventListener("resize", this.computeLineHeight);
  },
  methods: {
    computeLineHeight() {
      this.numberOfLines = Math.floor(this.$refs.lineContainer.getBoundingClientRect().height / this.$refs.ghostLine.getBoundingClientRect().height);
    }
  },
  computed: {
    ...mapState(['data', 'config']),
    toDisplay() {
      return this.data.lines.filter(d => this.config.isoToDisplay.scroll.indexOf(d.currencyIso) >= 0);
    },
    valuesByLetters() {
      /*
       * On range toutes les valeurs dans autant de tableau qu'il y a de première lettre à chaque pays
       * Donc on range toutes les pays qui commencent par "A" ensemble, et ainsi de suite
       * Le résultat est un objet, dont chaque clé est une lettre, et chaque valeur une liste de devises
       */
      let byLetter = {};
      for (const d of this.toDisplay) {
        let substr = d.countryDefaultName.substr(0, 1).toUpperCase();
        byLetter[substr] ? byLetter[substr].push(d) : byLetter[substr] = [d];
      }
      // On trie les clés de l'objet final par ordre alphabétique (oui c'est compliqué de trier les clés d'un objet)
      return Object.keys(byLetter).sort()
        .reduce((accumulator, key) => {
          accumulator[key] = byLetter[key];
          return accumulator;
        }, {});
    },
    lines() {
      let lines = [];
      /*
       * On va boucler sur toutes les lignes pour les répartir par plage de lettre
       *
       * this.numberOfLines est précalculé, voir "computeLineHeight" et les eventListener au resize dans mounted() et unmounted()
       * Comme le nombre de lignes est fixé, et qu'on souhaite répartir équitablement toutes nos devises sur nos lignes,
       * notre cible est d'avoir (this.toDisplay.length / this.numberOfLines) éléments par ligne
       *
       * Pour chaque "première lettre" de devise, on va rajouter toutes les devises correspondantes dans la ligne
       * courante, voir si cette cible est atteinte pour la ligne courante, et en fonction soit passer à la ligne
       * suivante, soit continuer à remplir la même ligne avec les devises de la lettre suivante
       */
      // On vérifie qu'on a bien les infos précalculées
      if (this.numberOfLines > 0 && typeof this.valuesByLetters === 'object') {
        // On récup toutes les premières lettres
        let letters = Object.keys(this.valuesByLetters);
        //  Préparation de notre première ligne
        let currentLine = new Line();
        // Pour chaque "première lettre"...
        for (let i = 0; i < letters.length; i++) {
          // On récupère les infos / on initialise éventuellement si c'est une nouvelle ligne
          const letter = letters[i];
          let isLastLetter = i === letters.length - 1;
          if (currentLine.rangeStart == null) currentLine.rangeStart = letter;
          // On rajoute toutes les devises de la lettre courante
          this.valuesByLetters[letter].forEach(v => currentLine.data.push(v));
          // Est-ce qu'on a atteint notre cible pour la ligne actuelle ? (ou accessoirement, est-ce que c'est la dernière lettre)
          let threshold = this.toDisplay.length / this.numberOfLines;
          let val = currentLine.data.length + (i < letters.length - 1 ? letters[i + 1].length : 0);
          if (val > threshold || isLastLetter) {
            // Oui on atteint notre quota (ou on a fini), on ajoute la ligne qu'on vient de finir à la liste des lignes et on continue
            currentLine.rangeEnd = letter;
            lines.push(currentLine);
            currentLine = new Line();
          }
        }
      }
      return lines;
    }
  },
}
</script>

<style>
.y-data-horizontal-main {
  height: 100%;
}

.y-data-horizontal-lines-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.y-data-horizontal-lines-container > div {
  flex-grow: 1
}

.y-data-horizontal-lines-container > div:not(:last-child) {
  border-bottom: 1px v-bind('config.theme.colors.elements.table.scroll.lines.border') solid;
}

.y-data-scroll-horizontal-line-ghost {
  position: absolute;
  opacity: 0;
}

.y-data-scroll-line {
  /** Si on dynamise, il faut recalculer le nombre de lignes **/
  height: 2em;
}

.y-data-container .y-data-scroll-line .y-data-scroll-horizontal-line-scroll > :nth-child(even) { /** cible chaque ligne paires **/
  background-color: v-bind('config.theme.colors.elements.table.scroll.lines.even.background');
  color: v-bind('config.theme.colors.elements.table.scroll.lines.even.text');
}

.y-data-container .y-data-scroll-line .y-data-scroll-horizontal-line-scroll > :nth-child(odd) { /** cible chaque ligne impaires **/
  background-color: v-bind('config.theme.colors.elements.table.scroll.lines.odd.background');
  color: v-bind('config.theme.colors.elements.table.scroll.lines.odd.text');
}

</style>
