<template>
  <div class="y-display-jumbotron-container" v-bind:class="{
          'y-display-jumbotron-show': show,
          'y-display-jumbotron-hide': !show
       }">
    <span v-html="messageToDisplay"/>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DisplayJumbotron",
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    cycleCount: 0
  }),
  watch: {
    show(v) {
      if (v) {
        this.cycleCount++;
      }
    }
  },
  computed: {
    ...mapState(['config']),
    classesIn() {
      return 'y-display-jumbotron-show'
    },
    classesOut() {
      return 'y-display-jumbotron-hide'
    },
    messageToDisplay() {
      return this.cycleCount % 2 === 0 ?
        this.config.legacy.msg1 : this.config.legacy.msg2; // TODO A changer
    }
  }
}
</script>

<style scoped>
/** Message intermittent **/

.y-display-jumbotron-container span {
  font-size: 3em;
  font-weight: bold;
}

.y-display-jumbotron-container {
  width: 100%;
  height: 100%;
  color: v-bind('config.theme.colors.elements.jumbotron.text');
  background-color: v-bind('config.theme.colors.elements.jumbotron.background');
  transition: transform .5s ease, border-radius .5s ease;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.y-display-jumbotron-show {
  transform: scale(100%);
  border-radius: 0;
}

.y-display-jumbotron-hide {
  transform: scale(0%);
  border-radius: 50%;
}
</style>
