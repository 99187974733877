<template>
  <div class="y-title-container">
    <div>
      <auto-refresh-clock refresh-every-minute format="LT"/>
    </div>
    <div>
      <h1>{{ $t('title') }}</h1>
      <!--    <h2>{{ config.titre }}</h2>-->
    </div>
    <div>
      <auto-refresh-clock refresh-daily format="LL"/>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import AutoRefreshClock from "@/components/misc/AutoRefreshClock";

export default {
  name: "DisplayTitle",
  components: {AutoRefreshClock},
  computed: {...mapState(['config'])},
}
</script>

<style scoped>
/** Titre **/
.y-title-container {
  background-color: v-bind('config.theme.colors.elements.topBar.background');
  color: v-bind('config.theme.colors.elements.topBar.text');
  display: flex;
  align-items: center;
  border-bottom: .1em v-bind('config.theme.colors.elements.topBar.border') solid;
  font-size: v-bind('config.theme.sizes.relativeTopBar')
}

.y-title-container div {
  flex-grow: 1;
}

.y-title-container > div:first-child, .y-title-container > div:last-child {
  width: 5em;
}

h1 {
  font-weight: bolder;
  font-size: 2em;
}

</style>
