<template>
  <div>
    <span v-bind:class="{'y-fixed-size-currency-base': !short}">
      {{ row.base }}
    </span>
    <span>
      {{ row.currencyDefaultName }}
    </span>
  </div>
</template>

<script>
export default {
  name: "DisplayCurrencyScrollElementCurrencyCell",
  props: {
    row: {
      type: Object
    },
    short: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
div {
  display: flex;
}

div span:nth-child(1) {
  text-align: right;
  margin-right: .3em;
}

.y-fixed-size-currency-base {
  width: 3em;
  flex-grow: 0;
}

div span:nth-child(2) {
  text-align: left;
  flex-grow: 1;
}
</style>
