import {createStore} from 'vuex'
import dataBusiness, {dataStructureExample} from "@/business/data-business";
import _get from 'lodash/get'
import _set from 'lodash/set'
import moment from "moment";

export default createStore({
  state: {
    config: dataStructureExample.config,
    data: dataStructureExample.data,
    currentLocale: 'fr',
    error: false,
    loaded: false,
    connectionStatus: dataBusiness.connectionStatus.DISCONNECTED,
    saving: false,
    saveError: false,
    violationDetected: false
  },
  getters: {
    getConfigKey: (state) => (key) => {
      let get = _get(state.config, key);
      return get
    }
  },
  mutations: {
    setConfig(state, config) {
      state.config = config;
    },
    setData(state, data) {
      state.data = data;
    },
    setError(state, error) {
      state.error = error;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },
    setConnectionStatus(state, connectionStatus) {
      state.connectionStatus = connectionStatus;
    },
    setSaving(state, saving) {
      state.saving = saving;
    },
    setSaveError(state, saveError) {
      state.saveError = saveError;
    },
    setConfigKey(state, {key, value}) {
      _set(state.config, key, value);
    },
    setCurrentLocale(state, locale) {
      state.currentLocale = locale;
      moment.locale(locale)
    }
  },
  actions: {
    fetchDataOnce(context) {
      dataBusiness.fetchData().then(d => {
        context.commit('setData', d.data);
        context.commit('setConfig', d.config);
        context.commit('setLoaded', true);
        context.commit('setCurrentLocale', d.currentLocale);
      }).catch(() => context.commit('setError', true));
    },
    subscribeToDataSource(context) {
      dataBusiness.subscribe(
        (d => {
          // console.log("Update received", d)
          context.commit('setData', d.data);
          context.commit('setConfig', d.config);
          context.commit('setLoaded', true);
          context.commit('setCurrentLocale', d.currentLocale);
        }),
        ((connectionStatus) => {
          context.commit('setConnectionStatus', connectionStatus)
        })
      );
    },
    saveConfig(context) {
      context.commit('setSaving', true);
      context.commit('setSaveError', false);
      dataBusiness.saveConfig(context.state.config, context.state.config.medium) // XXX A modif quand on aura statué sur l'emplacement de medium
        .catch(() => context.commit('setSaveError', true))
        .finally(() => context.commit('setSaving', false))
    }
  },
  modules: {}
})
