import Matercolor from "matercolors";
import get from "lodash/get"
import Color from 'color'

export function buildColorPalette(firstColor, secondaryColor) {
  let firstPalette = new Matercolor(firstColor).palette;
  let colorTheme = {};
  fillThemeObjectWithPalette(colorTheme, firstPalette, 'primary');
  fillThemeObjectWithPalette(colorTheme, firstPalette, 'analogous.primary', 'analogousPrimary');
  fillThemeObjectWithPalette(colorTheme, firstPalette, 'analogous.secondary', 'analogousSecondary');
  fillThemeObjectWithPalette(colorTheme, firstPalette, 'triadic.primary', 'triadicPrimary');
  fillThemeObjectWithPalette(colorTheme, firstPalette, 'triadic.secondary', 'triadicSecondary');
  if (secondaryColor) {
    fillThemeObjectWithPalette(colorTheme, new Matercolor(secondaryColor).palette, 'primary', 'complementary');
  } else {
    fillThemeObjectWithPalette(colorTheme, firstPalette, 'complementary');

  }
  return colorTheme;
}

function buildThemePalettePartFor(palette, highContrast, colorType) {
  return {
    veryLight: highContrast ? new Color(palette[colorType].c50).lighten(.03).hex() : palette[colorType].c50,
    light: highContrast ? palette[colorType].c50 : palette[colorType].c100,
    mildLight: highContrast ? palette[colorType].c100 : palette[colorType].c200,
    mildDark: highContrast ? palette[colorType].c800 : palette[colorType].c700,
    dark: highContrast ? palette[colorType].c900 : palette[colorType].c800,
    veryDark: highContrast ? new Color(palette[colorType].c900).darken(.03).hex() : palette[colorType].c900,
  }

}

export function buildTheme(palette, highContrast, dark) {
  const theme = {
    primary: {...buildThemePalettePartFor(palette, highContrast, 'primary')},
    complementary: {...buildThemePalettePartFor(palette, highContrast, 'complementary')},
    analogousPrimary: {...buildThemePalettePartFor(palette, highContrast, 'analogousPrimary')},
  }
  return {
    default: {
      text: dark ? theme.complementary.veryLight : theme.complementary.veryDark,
      background: dark ? theme.primary.veryDark : theme.primary.veryLight
    },
    footer: {
      text: null, // idem default
      background: dark ? theme.primary.veryDark : theme.primary.veryLight
    },
    jumbotron: {
      text: null, // idem default
      background: dark ? theme.primary.veryDark : theme.primary.veryLight
    },
    topBar: {
      text: null, // idem default
      background: dark ? theme.primary.veryDark : theme.primary.veryLight,
      border: dark ? theme.analogousPrimary.light : theme.analogousPrimary.dark
    },
    table: {
      scroll: {
        lines: {
          even: {
            text: null, // idem default
            background: dark ? theme.primary.dark : theme.primary.light,
          },
          odd: {
            text: null, // idem default
            background: dark ? theme.primary.mildDark : theme.primary.mildLight,
          },
          border: dark ? theme.analogousPrimary.light : theme.analogousPrimary.dark
        }
      },
      main: {
        lines: {
          even: {
            text: null, // idem default
            background: dark ? theme.primary.dark : theme.primary.light,
          },
          odd: {
            text: null, // idem default
            background: dark ? theme.primary.mildDark : theme.primary.mildLight,
          },
          border: dark ? theme.analogousPrimary.light : theme.analogousPrimary.dark
        }
      }
    }
  }
}

function fillThemeObjectWithPalette(receiver, palette, colorTypeSource, colorTypeDestination = null) {
  if (!colorTypeDestination) {
    colorTypeDestination = colorTypeSource;
  }
  receiver[colorTypeDestination] = {};
  receiver[colorTypeDestination] = {
    'c50': get(palette, colorTypeSource)[50],
    'c100': get(palette, colorTypeSource)[100],
    'c200': get(palette, colorTypeSource)[200],
    'c300': get(palette, colorTypeSource)[300],
    'c400': get(palette, colorTypeSource)[400],
    'c500': get(palette, colorTypeSource)[500],
    'c600': get(palette, colorTypeSource)[600],
    'c700': get(palette, colorTypeSource)[700],
    'c800': get(palette, colorTypeSource)[800],
    'c900': get(palette, colorTypeSource)[900],
    'a100': get(palette, colorTypeSource).A100,
    'a200': get(palette, colorTypeSource).A200,
    'a400': get(palette, colorTypeSource).A400,
    'a700': get(palette, colorTypeSource).A700
  }
}
