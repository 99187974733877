<template>
  <div class="split-view">
    <div class="creator-panel">

      <n-space vertical>
        <n-space align="center">
          <h1 id="creator-title">YodaDisplay Creator</h1>
          <n-button type="primary" :loading="saving" :disabled="saving" @click="onSaveButtonClicked">Sauvegarder</n-button>
        </n-space>

        <n-tabs justify-content="space-evenly" type="line" animated v-model:value="currentTab">
          <n-tab-pane name="structure" tab="Structure" display-directive="show">
            <structure-editor/>
          </n-tab-pane>
          <n-tab-pane name="theme" tab="Thème" display-directive="show">
            <theme-editor/>
          </n-tab-pane>
          <n-tab-pane name="text" tab="Langue et texte" display-directive="show">
            <language-editor/>
          </n-tab-pane>
          <n-tab-pane name="timers" tab="Minuterie" display-directive="show">
            <timer-editor/>
          </n-tab-pane>
        </n-tabs>


      </n-space>

    </div>

    <editor-preview class="preview-panel-container"/>

    <n-button class="absolute-bottom-right" type="primary" :loading="saving" :disabled="saving" @click="onSaveButtonClicked">Sauvegarder</n-button>

  </div>
</template>

<script>
import {NButton, NSpace, NTabPane, NTabs} from 'naive-ui'
import StructureEditor from "@/components/creator/StructureEditor";
import ThemeEditor from "@/components/creator/ThemeEditor";
import EditorPreview from "@/components/creator/EditorPreview";
import LanguageEditor from "@/components/creator/LanguageEditor";
import TimerEditor from "@/components/creator/TimersEditor";
import {mapState} from "vuex";

export default {
  name: "CreatorView",
  components: {
    TimerEditor,
    LanguageEditor,
    EditorPreview,
    ThemeEditor,
    StructureEditor,
    NSpace,
    NTabs,
    NTabPane,
    NButton,
  },
  data: () => ({
    showJumbotron: false,
    baseCurrencySymbol: null,
    currentTab: 'structure',
    tmpLanguageSwitchValue: null
  }),
  computed: {...mapState(['saving', 'saveError'])},
  mounted() {
    this.baseCurrencySymbol = this.$store.getters.getConfigKey('text.global.baseCurrencySymbol');
  },
  watch: {
    baseCurrencySymbol(v) {
      this.$store.commit('setConfigKey', {key: 'text.global.baseCurrencySymbol', value: v});
    },
    currentTab(v) {
      if (v === 'text') {
        this.tmpLanguageSwitchValue = this.$store.state.config.timers.languageSwitch;
        this.$store.commit('setConfigKey', {key: 'timers.languageSwitch', value: -1});
      } else {
        this.$store.commit('setConfigKey', {key: 'timers.languageSwitch', value: this.tmpLanguageSwitchValue});
      }
    }
  },
  methods: {
    onSaveButtonClicked() {
      this.$store.dispatch("saveConfig");
    }

  }
}
</script>

<style>
.split-view {
  display: flex;
  position: relative;
}

.creator-panel {
  width: 50%;
  padding: 1em;
  position: relative;
  line-height: 1;
  /*text-align: center;*/ /* Antho aime pas trop */
}

.creator-panel .n-space {
  /* Antho aime pas trop */
  /*justify-content: center !important; !* Autre solution, mettre le paramétrage de style directement sur le composant n-space en question. Peste vs. cholera *!*/
}

.creator-panel .n-transfer {
  width: 100%;
}

.creator-panel h1 {
}

.creator-panel h3 {
  margin-block-end: .8em;
}

.creator-panel h4 {
  margin-block-end: .3em;
  color: #364157;
}

.creator-panel h5 {
  margin-block-end: .1em;
  color: #4e678a;
}

.creator-panel p, .creator-panel li {
  text-align: initial;
  margin-block-start: 0;
  margin-block-end: .4em;
}

.preview-panel-container {
  width: 50%;
  height: 100vh;
}

.absolute-bottom-right {
  position: fixed;
  bottom: 1em;
  right: 1em;
}

</style>
