/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

export async function loadFonts() {
  const webFontLoader = await import(/* webpackChunkName: "webfontloader" */'webfontloader')

  webFontLoader.load({
    google: {
      families: [
        'Josefin Sans:100,200,300,400,500,600,700',
        'Roboto:100,300,400,500,700,900',
        'Roboto Condensed:300,400,700',
        'Albert Sans:100,200,300,400,500,600,700,800,900',
        'Comic Neue:300,400,700',
        'Chakra Petch:300,400,500,600,700',
        'Edu QLD Beginner:400,500,600,700',
        'Permanent Marker:400',
        'Manrope:200,300,400,500,600,700,800',
        'Koulen:400',
      ],
    },
  })
}
