<template>
  <div class="y-data-scroll-horizontal-line-scroll-container">
    <div class="y-data-scroll-horizontal-line-header">{{rangeStart}}<span v-if="rangeEnd!==rangeStart">-{{rangeEnd}}</span></div>
    <div class="y-data-scroll-horizontal-line-scroll y-data-common"
         v-bind:class="{'y-data-scroll-pause-animation': !scroll}"
         :style="computedStyle"
    >
      <!--  Première iteration  -->
      <div style="display: none;" v-if="offsetColor"/><!-- On rajoute un élément pour changer la couleur de départ (l'alternance des couleurs se fait avec un sélecteur CSS :nth-child(even) -->
      <display-currency-scroll-element class="y-table-horizontal-cell" v-for="(l,i) in data" :key="i" :row="l" labelled short/>
      <!--  Les données sont triplée (pour être sûr de retomber sur les bonnes couleurs quand on boucle) -->
      <display-currency-scroll-element class="y-table-horizontal-cell" v-for="(l,i) in data" :key="i+'bis'" :row="l" labelled short/>
      <display-currency-scroll-element class="y-table-horizontal-cell" v-for="(l,i) in data" :key="i+'ter'" :row="l" labelled short/>

      <!--
       Si la ligne ne contient qu'un seul élément, notre boucle devient visible
       (on voit qu'il n'y a plus rien après le dernier élément, et lorsque la boucle repart au début, le deuxième élément fait comme "apparaitre" soudainement)
       Du coup s'il n'y a qu'un seul élément on affiche non pas 3 mais 6 fois l'élément.
       On met 3 fois l'élément manuellement car un v-for nous forcerait à encapsuler les display-scroll-element dans un contenant, et ça pourrait fausser le CSS
       -->
      <display-currency-scroll-element v-if="data.length===1" class="y-table-horizontal-cell" :row="data[0]" labelled short/>
      <display-currency-scroll-element v-if="data.length===1" class="y-table-horizontal-cell" :row="data[0]" labelled short/>
      <display-currency-scroll-element v-if="data.length===1" class="y-table-horizontal-cell" :row="data[0]" labelled short/>


    </div>
  </div>
</template>

<script>
import DisplayCurrencyScrollElement from "@/components/displayparts/currency/DisplayCurrencyScrollElement.vue";
import {mapState} from "vuex";

const DURATION_PER_ELEMENT = 15;

export default {
  name: "DisplayCurrencyScrollHorizontalLine",
  props: {
    scroll: Boolean,
    data: Array,
    rangeStart: String,
    rangeEnd: String,
    offsetColor: {
      type: Boolean,
      default: false
    }
  },
  components: {DisplayCurrencyScrollElement},
  computed: {
    ...mapState(['config']),
    computedStyle() {
      const nbOfElementPerLine = this.data.length === 1 ? 6 : 3; // voir le commentaire dans la partie template
      return `animation-duration: ${this.data.length * DURATION_PER_ELEMENT * nbOfElementPerLine}s;`
    }
  },
}
</script>

<style>
@keyframes scroll-horizontal {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(calc(-2 * 100% / 3));
  }
}

.y-data-container .y-data-scroll-horizontal-line-scroll .y-row-sm-txt {
  font-size: .8em;
}

.y-data-container .y-data-scroll-horizontal-line-scroll .y-table-row-cell span:not(.y-row-sm-txt) {
  /*font-weight: bold;*/
}

.y-data-scroll-horizontal-line-header {
  width: 3.5em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  z-index: 5;
  background-color: v-bind('config.theme.colors.elements.table.scroll.lines.even.background');
  color: v-bind('config.theme.colors.elements.table.scroll.lines.even.text');
}

.y-data-scroll-horizontal-line-scroll-container {
  overflow: hidden;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.y-data-scroll-pause-animation {
  animation-play-state: paused;
}

.y-data-scroll-horizontal-line-scroll {
  position: absolute;

  display: flex;
  height: 100%;
  font-size: 1em;

  animation-name: scroll-horizontal;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.y-data-scroll-horizontal-line-scroll .y-table-horizontal-cell {
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-right: 1px v-bind('config.theme.colors.elements.table.scroll.lines.border') solid;
}

.y-data-scroll-horizontal-line-scroll .y-flag span { /* drapeau lignes défilantes */
  font-size: 1em;
  /* Depuis qu'on a fixé le line-height sur le conteneur du layout, ça fait des trucs chelou, donc pour l'instant on vire *!*/
  /*box-shadow: 0 0 .2em rgba(0,0,0,.5); ! */
}

.y-data-scroll-horizontal-line-scroll .y-table-horizontal-cell > div {
  padding: 0 .5em;
}
</style>
