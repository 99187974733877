<template>
  <div>
    <flag v-if="!isImage" :iso="iso" :squared="false"/>
    <span class="image-flag" v-else>
      <img :src="imageUrl"/>
    </span>
  </div>

</template>

<script>
export default {
  name: "CountryFlag",
  props: {
    iso: {
      type: String
    },
    type: {
      type: String
    }
  },
  computed: {
    computedType() {
      switch (this.type) {
        case "flat":
        case "embossed":
        case "square-3d":
        case "suitcase":
        case "waving-flag":
          return this.type;
        default: return "flat"
      }
    },
    isImage() {
      return this.computedType !== "flat";
    },
    lowercaseIso() {
      return this.iso ? this.iso.toLowerCase() : null;
    },
    imageUrl() {
      switch (this.type) {
        case "embossed": return `https://ydp.io/Resources/images/flags_rect_80/${this.lowercaseIso}.png`
        case "square-3d": return `https://ydp.io/Resources/images/flag_glossy_square_icon_3d/${this.lowercaseIso}.png`
        case "suitcase": return `https://ydp.io/Resources/images/flag_traveling_icon/${this.lowercaseIso}.png`
        case "waving-flag": return `https://ydp.io/Resources/images/flag_waving_flag/${this.lowercaseIso}.png`
        default: return null;
      }
    }
  }
}
</script>

<style scoped>
img {
  width: 1.5em;
}

.image-flag {
  display: flex;
}
</style>
