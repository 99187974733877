<template>
  <div class="y-data-main y-data-common">
    <div class="y-table-row y-table-row-header">
      <div v-if="config.theme.structure.columns.main.indexOf('iso')>=0" class="y-cell-iso">&nbsp;</div>
      <div v-if="config.theme.structure.columns.main.indexOf('flag')>=0" class="y-cell-flag">&nbsp;</div>
      <div v-if="config.theme.structure.columns.main.indexOf('country')>=0" class="y-cell-country">&nbsp;</div>
      <div v-if="config.theme.structure.columns.main.indexOf('currency')>=0" class="y-cell-currency">&nbsp;</div>
      <div class="y-cell-buy-or-sell" v-bind:class="{'y-cell-buy-or-sell-double': config.theme.structure.columns.main.indexOf('buy-both')===-1}">{{$t('weBuyMain')}}</div>
      <div class="y-cell-buy-or-sell" v-bind:class="{'y-cell-buy-or-sell-double': config.theme.structure.columns.main.indexOf('sell-both')===-1}">{{$t('weSellMain')}}</div>
    </div>
    <display-currency-scroll-element class="y-table-row" v-for="(l,i) in toDisplay" :key="i" :row="l" main/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import DisplayCurrencyScrollElement from "@/components/displayparts/currency/DisplayCurrencyScrollElement.vue";

export default {
  name: "DisplayCurrencyMainTable",
  components: {DisplayCurrencyScrollElement},
  computed: {
    ...mapState(['data', 'config']),
    toDisplay() {
      return this.data.lines.filter(d => this.config.isoToDisplay.main.indexOf(d.currencyIso) >= 0);
    }
  },
}
</script>

<style>
/** Conteneur des cours fixes **/
.y-data-container .y-data-main {
  display: flex;
  flex-direction: column;
}

.y-data-container .y-data-main > :nth-child(even) { /** cible chaque ligne paires **/
  background-color: v-bind('config.theme.colors.elements.table.main.lines.even.background');
  color: v-bind('config.theme.colors.elements.table.main.lines.even.text');
}

.y-data-container .y-data-main > :nth-child(odd) { /** cible chaque ligne impaires **/
  background-color: v-bind('config.theme.colors.elements.table.main.lines.odd.background');
  color: v-bind('config.theme.colors.elements.table.main.lines.odd.text');
}

/** Lignes des cours (spécifique cours fixes) **/
.y-data-container .y-data-main .y-table-row .y-flag span { /* drapeau lignes fixes */
  /*font-size: 2em;*/
}

.y-data-container .y-data-main .y-table-row > div { /* texte lignes fixes */
  font-size: v-bind('config.theme.sizes.relativeMainTable');
  /*font-weight: 400;*/
}

.y-data-container .y-data-main .y-table-row.y-table-row-header > div { /* texte header partie fixe */
  white-space: break-spaces;
}

.y-data-container .y-data-main .y-table-row .y-table-row-cell {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.y-data-container .y-data-main .y-table-row .y-table-row-cell .y-row-sm-txt {
  /*font-size: 40%;*/
  /*font-weight: bold;*/
}

</style>
