<template>
  <div class="y-data-table-scroll-container">
    <div class="y-data-table-scroll y-data-common"
         v-bind:class="{'y-data-scroll-pause-animation': !scroll}"
         :style="computedStyle">
      <!--  Première iteration  -->
      <display-currency-scroll-element class="y-table-row" v-for="(l,i) in toDisplay" :key="i" :row="l"/>
      <!--  Les données sont triplée (pour être sûr de retomber sur les bonnes couleurs quand on boucle) -->
      <display-currency-scroll-element class="y-table-row" v-for="(l,i) in toDisplay" :key="i+'bis'" :row="l"/>
      <display-currency-scroll-element class="y-table-row" v-for="(l,i) in toDisplay" :key="i+'ter'" :row="l"/>

      <template v-if="toDisplay.length < thresholdBeforeDoubling"><!-- XXX Mesurer dynamiquement la taille d'une et calculer le nombre d'itérations nécessaires en conséquence -->
        <display-currency-scroll-element class="y-table-row" v-for="(l,i) in toDisplay" :key="i+'quad'" :row="l"/>
        <display-currency-scroll-element class="y-table-row" v-for="(l,i) in toDisplay" :key="i+'penta'" :row="l"/>
        <display-currency-scroll-element class="y-table-row" v-for="(l,i) in toDisplay" :key="i+'hexa'" :row="l"/>
      </template>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import DisplayCurrencyScrollElement from "@/components/displayparts/currency/DisplayCurrencyScrollElement.vue";

const DURATION_PER_LINE = 5;

// S'il y a trop peu d'éléments dans la liste, on double le contenu (sinon à la fin de l'animation de scroll il y a du vide en bas de la liste)
const SCROLL_ELEMENT_COUNT_THRESHOLD_BEFORE_DOUBLING = 20;

export default {
  name: "DisplayCurrencyScrollTable",
  props: {
    scroll: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    thresholdBeforeDoubling: SCROLL_ELEMENT_COUNT_THRESHOLD_BEFORE_DOUBLING
  }),
  components: {DisplayCurrencyScrollElement},
  computed: {
    ...mapState(['data', 'config']),
    toDisplay() {
      return this.data.lines.filter(d => this.config.isoToDisplay.scroll.indexOf(d.currencyIso) >= 0);
    },
    computedStyle() {
      // Si on a dû doubler la liste, on doit augmenter la durée de l'animation (sinon ça va défiler 2 fois trop vite)
      const coef = this.toDisplay.length < SCROLL_ELEMENT_COUNT_THRESHOLD_BEFORE_DOUBLING ? 2 : 1
      return `animation-duration: ${this.toDisplay.length * DURATION_PER_LINE * coef}s;`
    }
  },
}
</script>

<style>
  @keyframes scroll {
    from { transform: translateY(0%); }
    to { transform: translateY(calc(-2 * 100% / 3)); }
  }

.y-data-table-scroll {
  animation: 60s linear infinite running scroll;
}

.y-data-scroll-pause-animation {
  animation-play-state: paused;
}


/** Conteneur des cours défilant (coquille pour gérer le défilement) **/
.y-data-container .y-data-table-scroll-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

/** Conteneur des cours défilant (partie qui défile, contient vraiment les lignes) **/

.y-data-container .y-data-table-scroll {
  position: absolute;
  width: 100%;
  z-index: 0;
}

.y-data-container .y-data-table-scroll > :nth-child(even) { /** cible chaque ligne paires **/
  background-color: v-bind('config.theme.colors.elements.table.scroll.lines.even.background');
  color: v-bind('config.theme.colors.elements.table.scroll.lines.even.text');
}

.y-data-container .y-data-table-scroll > :nth-child(odd) { /** cible chaque ligne impaires **/
  background-color: v-bind('config.theme.colors.elements.table.scroll.lines.odd.background');
  color: v-bind('config.theme.colors.elements.table.scroll.lines.odd.text');
}

/** Lignes des cours (spécifique cours défilants) **/

.y-data-container .y-data-table-scroll .y-table-row .y-flag span { /* drapeau lignes défilantes */
  /*font-size: 2em;*/
}

.y-data-container .y-data-table-scroll .y-table-row > div { /* texte lignes défilantes */
  font-size: 1em;
  /*font-weight: 450;*/
}

.y-data-container .y-data-table-scroll .y-row-sm-txt {
  font-size: .8em;
  /*font-weight: bold;*/
}
</style>
