<template>
  <n-space vertical>
    <h1>Minuterie et durées</h1>
    <h2>Messages intermittents</h2>
    <n-checkbox v-model:checked="enableJumbotron">Afficher les messages intermittents</n-checkbox>
    <n-space vertical v-if="enableJumbotron">
      <h3>Pause entre chaque affichage</h3>
      <n-input-number :min="5" :max="300" v-model:value="jumbotronLoopDuration">
        <template #suffix>secondes</template>
      </n-input-number>
      <h3>Durée de l'affichage du message</h3>
      <n-input-number :min="1" :max="10" v-model:value="jumbotronDisplayDuration">
        <template #suffix>secondes</template>
      </n-input-number>
    </n-space>
    <h2>Langues</h2>
    <p>N'aura d'incidence seulement si vous avez sélectionné plusieurs langues dans l'onglet "Langue et texte"</p>
    <n-input-number :min="10" :max="120" v-model:value="languageSwitchDelay">
      <template #suffix>secondes</template>
    </n-input-number>
  </n-space>
</template>

<script>
import {NCheckbox, NInputNumber, NSpace} from 'naive-ui'

export default {
  name: "TimerEditor",
  components: {NInputNumber, NSpace, NCheckbox},
  data: () => ({
    enableJumbotron: false,
    jumbotronLoopDuration: -1,
    jumbotronDisplayDuration: 0,
    languageSwitchDelay: 0,
  }),
  mounted() {
    let jumbotronIntervalInMs = this.$c('timers.jumbotron.interval');
    if (jumbotronIntervalInMs < 0) {
      this.enableJumbotron = false;
      this.jumbotronLoopDuration = 0;
    } else {
      this.enableJumbotron = true;
      this.jumbotronLoopDuration = jumbotronIntervalInMs / 1000; // On passe en secondes
    }
    this.jumbotronDisplayDuration = this.$c('timers.jumbotron.displayTime') / 1000;
    this.languageSwitchDelay = this.$c('timers.languageSwitch') / 1000;
  },
  watch: {
    enableJumbotron(v) {
      if (v) {
        this.$store.commit('setConfigKey', {key: 'timers.jumbotron.interval', value: 60000})
        this.jumbotronLoopDuration = 60;
      } else {
        this.$store.commit('setConfigKey', {key: 'timers.jumbotron.interval', value: -1})
        this.jumbotronLoopDuration = 0;
      }
    },
    jumbotronLoopDuration(v) {
      if (Number.isSafeInteger(v)) {
        this.$store.commit('setConfigKey', {key: 'timers.jumbotron.interval', value: v * 1000})
      }
    },
    jumbotronDisplayDuration(v) {
      if (Number.isSafeInteger(v)) {
        this.$store.commit('setConfigKey', {key: 'timers.jumbotron.displayTime', value: v * 1000})
      }
    },
    languageSwitchDelay(v) {
      if (Number.isSafeInteger(v)) {
        this.$store.commit('setConfigKey', {key: 'timers.languageSwitch', value: v * 1000})
      }
    }
  }
}
</script>

<style scoped>

</style>
