<template>
  <n-space vertical>
    <h2 id="creator-languages">Langues et texte</h2>

    <p>Pour afficher plusieurs langues sur votre panneau d'affichage, </p>

    <n-transfer
      v-model:value="selectedLanguages"
      :options="availableLanguages"
      source-title="Languages disponibles"
      target-title="Languages à afficher"
      filterable
    />

    <n-card>
      <n-tabs type="line" justify-content="space-evenly" animated v-model:value="selectedLocale">
        <n-tab-pane :name="l" :tab="labelForLanguageCode[l]" v-for="l in selectedLanguages" :key="l">
          <language-editor-tab :language="l"/>
        </n-tab-pane>
      </n-tabs>
    </n-card>
  </n-space>
</template>

<script>
import {NCard, NSpace, NTabPane, NTabs, NTransfer} from 'naive-ui'
import LanguageEditorTab from "@/components/creator/LanguageEditorTab";
import {mapState} from "vuex";

export default {
  name: "LanguageEditor",
  components: {
    NSpace,
    NTransfer,
    NCard,
    NTabs,
    NTabPane,
    LanguageEditorTab
  },
  data: () => ({
    selectedLanguages: ['fr', 'en'],
    selectedLocale: 'fr',

    availableLanguages: [
      {label: 'Français', value: 'fr'},
      {label: 'Anglais', value: 'en'}
    ],
  }),
  computed: {
    labelForLanguageCode() {
      let r = {};
      this.availableLanguages.forEach(v => r[v.value] = v.label);
      return r;
    },
    ...mapState(['currentLocale'])
  },
  mounted() {
    this.selectedLanguages = Object.keys(this.$store.state.config.text.languages);
    this.selectedLocale = this.currentLocale;
  },
  watch: {
    currentLocale(v) {
      this.selectedLocale = v;
    },
    selectedLocale(v) {
      this.$store.commit('setCurrentLocale', v);
    },
  }
}
</script>

<style scoped>

</style>
