export default {
  install: (app, options) => {
    app.config.globalProperties.$t = (key) => {
      // XXX ajouter un fallback si la locale demandée n'existe pas
      return key.split('.').reduce((o, i) => {
        if (o) return o[i]
      }, options.store.state.config.text.languages[options.store.state.currentLocale])
    }

    // XXX ajouter les pays via une lib dédiée, avec override manuel possible https://www.npmjs.com/package/i18n-iso-countries
    // app.config.globalProperties.$tCountry = (key) => {}

    // XXX ajouter les devises, idem précedent : https://www.npmjs.com/package/@piotrgorecki/i18n-currency-name
    // app.config.globalProperties.$tCurrency = (key) => {}
  }
}
