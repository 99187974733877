<template>
  <n-space vertical>
    <h2 id="creator-structure">Structure de l'affichage</h2>
    <h3 id="creator-structure-scroll">Type de défilement</h3>
    <n-radio-group v-model:value="horizontalScrolling">
      <n-radio-button value="horizontal" label="Horizontal"/>
      <n-radio-button value="vertical" label="Vertical"/>
    </n-radio-group>

    <h3 id="creator-structure-columns">Colonnes</h3>
    <h4 id="creator-structure-columns-main">Lignes fixes</h4>
    <n-transfer
      ref="transfer" v-model:value="selectedMainColumns"
      :options="availableColumns"
      source-title="Colonnes disponibles"
      target-title="Colonnes à afficher"
    />
    <n-checkbox v-model:checked="groupPriceAndRateForMain" label="Grouper les colonnes cours et taux"/>
    <h4 id="creator-structure-columns-scroll">Lignes défilantes</h4>
    <n-checkbox v-model:checked="scrollColumnsSameAsMain" label="Identique aux lignes fixes"/>
    <div v-if="!scrollColumnsSameAsMain">
      <n-transfer
        ref="transfer" v-model:value="selectedScrollColumns"
        :options="availableColumns"
        source-title="Colonnes disponibles"
        target-title="Colonnes à afficher"
      />
      <n-checkbox v-model:checked="groupPriceAndRateForScroll" label="Grouper les colonnes cours et taux"/>
    </div>

  </n-space>
</template>

<script>
import isEqual from "lodash/isEqual";

import {NCheckbox, NRadioButton, NRadioGroup, NSpace, NTransfer} from 'naive-ui'

export default {
  name: "StructureEditor",
  components: {NCheckbox, NRadioGroup, NRadioButton, NTransfer, NSpace},
  data: () => ({
    horizontalScrolling: "",

    selectedScrollColumns: [],
    selectedMainColumns: [],
    scrollColumnsSameAsMain: false,

    groupPriceAndRateForMain: true,
    groupPriceAndRateForScroll: true,

    availableColumns: [
      {label: 'Code ISO', value: 'iso'},
      {label: 'Drapeau', value: 'flag'},
      {label: 'Pays', value: 'country', disabled: true},
      {label: 'Devise', value: 'currency'},
      {label: 'Achat', value: 'buy', disabled: true},
      {label: 'Vente', value: 'sell', disabled: true},
    ]
  }),
  mounted() {
    this.horizontalScrolling = this.$store.getters.getConfigKey('theme.structure.scrollingDirection');
    this.selectedMainColumns = this.processRealColumnForForm(this.$store.getters.getConfigKey('theme.structure.columns.main'));
    this.selectedScrollColumns = this.processRealColumnForForm(this.$store.getters.getConfigKey('theme.structure.columns.scroll'));
    this.scrollColumnsSameAsMain = isEqual(this.selectedScrollColumns, this.selectedMainColumns);
  },
  watch: {
    horizontalScrolling(v) {
      this.$store.commit('setConfigKey', {key: 'theme.structure.scrollingDirection', value: v});
    },
    selectedMainColumns(v) {
      this.updateMain(v);
    },
    selectedScrollColumns(v) {
      this.updateScroll(v);
    },
    scrollColumnsSameAsMain(v) {
      if (v) {
        this.groupPriceAndRateForScroll = this.groupPriceAndRateForMain;
        this.selectedScrollColumns = this.selectedMainColumns;
      }
    },
    groupPriceAndRateForMain(v) {
      if (this.scrollColumnsSameAsMain) {
        this.groupPriceAndRateForScroll = v;
      }
      this.updateMain(this.selectedMainColumns);
    },
    groupPriceAndRateForScroll() {
      this.updateScroll(this.selectedScrollColumns);
    }
  },
  methods: {
    updateMain(v) {
      this.$store.commit('setConfigKey', {key: 'theme.structure.columns.main', value: this.processColumnsBeforeSaving(v, false)});
      if (this.scrollColumnsSameAsMain) {
        this.selectedScrollColumns = v;
        this.$store.commit('setConfigKey', {key: 'theme.structure.columns.scroll', value: this.processColumnsBeforeSaving(v, true)});
      }
    },
    updateScroll(v) {
      this.$store.commit('setConfigKey', {key: 'theme.structure.columns.scroll', value: this.processColumnsBeforeSaving(v, true)});
    },
    processColumnsBeforeSaving(cols, isScroll) {
      let r = cols.filter(v => v !== 'buy' && v !== 'sell');

      if ((isScroll && this.groupPriceAndRateForScroll) || (!isScroll && this.groupPriceAndRateForMain)) {
        r.push('buy-both')
        r.push('sell-both')
      } else {
        r.push('buy-price')
        r.push('buy-rate')
        r.push('sell-price')
        r.push('sell-rate')
      }
      return r;
    },
    processRealColumnForForm(cols, isScroll) {
      if (isScroll) {
        this.groupPriceAndRateForScroll = cols.indexOf('buy-both') >= 0;
      } else {
        this.groupPriceAndRateForMain = cols.indexOf('buy-both') >= 0;
      }
      let r = cols.filter(v => !v.startsWith('buy-') && !v.startsWith('sell-'));
      r.push('buy');
      r.push('sell');
      return r;
    }
  }
}
</script>

<style scoped>

</style>
