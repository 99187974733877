<template>
  <div>
    <h5>{{ label }}</h5>
    <n-checkbox v-if="hasDefault" v-model:checked="useDefault" label="Utiliser la valeur par défaut"/>
    <n-color-picker v-if="!useDefault" v-model:value="color" :on-update:value="onColorChange" :swatches="swatches"
                    :modes="['hex']" :show-alpha="false"/>
  </div>
</template>

<script>
import {NCheckbox, NColorPicker} from 'naive-ui'
import {mapState} from "vuex";

export default {
  name: "ThemeColorElement",
  components: {NColorPicker, NCheckbox},
  props: {
    label: String,
    hasDefault: {
      type: Boolean,
      default: false
    },
    colorKey: String,
    swatches: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    color: null,
    useDefault: false
  }),
  computed: {
    ...mapState(['config']),
  },
  mounted() {
    this.color = this.$store.getters.getConfigKey('theme.colors.elements.' + this.colorKey);
    if (this.color === null) {
      this.useDefault = true;
    }
  },
  watch: {
    'config.theme.colors.elements': {
      deep: true,
      handler: function (v) {
        this.color = v[this.colorKey];
        if (this.color === null) {
          this.useDefault = true;
        }
      }
    },
    useDefault(v) {
      if (v) {
        this.$store.commit('setConfigKey', {key: 'theme.colors.elements.' + this.colorKey, value: null});
      } else {
        this.$store.commit('setConfigKey', {key: 'theme.colors.elements.' + this.colorKey, value: this.color});
      }
    }
  },
  methods: {
    onColorChange(v) {
      this.$store.commit('setConfigKey', {key: 'theme.colors.elements.' + this.colorKey, value: v});
    }
  }
}
</script>

<style scoped>

</style>
