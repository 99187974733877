import axios from 'axios';
import {buildColorPalette, buildTheme} from "@/business/theme-maker";
import config from "../config"
import merge from "lodash/merge"

const waitingDelayBeforeRetry = 5000; // Temps d'attente en ms entre 2 tentatives de reconnexion au serveur
export default {
  connectionStatus: {
    CONNECTED: Symbol('CONNECTED'),
    DISCONNECTED: Symbol('DISCONNECTED'),
    VIOLATION_ERROR: Symbol('VIOLATION_ERROR'),
  },

  fetchData() {
    return new Promise((resolve, reject) => {
      axios.get(config.apiUrl + '/v1/display-data-package/' + getCurrentSlug())
        .then((r) => resolve(prepareConfigurationObject(r.data)))
        .catch((e) => reject(e));
    })
  },

  subscribe(dataCallback, statusCallback) {

    let url = config.webSocketLiveDataEndpoint + "/" + getCurrentSlug();
    console.log("Trying connection for ", url)
    const ws = new WebSocket(url);

    ws.addEventListener('open', (e) => {
      console.log("Connection opened...", e)
      statusCallback(this.connectionStatus.CONNECTED);
    })

    ws.addEventListener('message', (e) => {
      let parse = JSON.parse(e.data);
      if (parse.type === "display-data") {
        dataCallback(prepareConfigurationObject(parse.payload));
      } else if (parse.type === 'force-refresh-request') {
        location.reload(); // On recharge la page
      }
    })

    ws.addEventListener('error', (evt) => {
      console.log("ws error", evt)
      ws.close();
    })

    let self = this; // Pour pouvoir rappeler notre méthode d'instance depuis la callback
    ws.addEventListener('close', (evt) => {
      if (evt.code === 1008) { // La fermeture a été causée par une violation du nombre d'affichages autorisés
        console.log("Connection closed because of simultaneous display output violation, retry in 5 seconds", evt)
        statusCallback(this.connectionStatus.VIOLATION_ERROR)
      } else {

        console.log("Connection closed, retry in 5 seconds", evt)
        // On retente la connexion automatiquement apres fermeture
        statusCallback(this.connectionStatus.DISCONNECTED);
      }

      setTimeout(() => self.subscribe(dataCallback, statusCallback), waitingDelayBeforeRetry);
    })

  },

  saveConfig(displayConfig) { // displayConfig
    return axios.post(config.apiUrl + '/v1/medium/' + getCurrentSlug() + '/config', {...displayConfig}) // TODO qu'est-ce qu'on fait du medium ?
  }
}

function getCurrentSlug() {
  let queryParams = new URL(window.location.href).searchParams;
  return queryParams.has('id') ? queryParams.get('id') : "m121t"; // TODO juste pour les tests
}


function prepareConfigurationObject(data) {

  // XXX Temporaire, juste pour les tests
  let queryParams = new URL(window.location.href).searchParams;
  let defaultDarkMode = queryParams.has('dark');
  let defaultHighContrast = queryParams.has('highContrast');
  let defaultPrimaryColor = queryParams.has('primaryColor') ? '#' + queryParams.get('primaryColor') : '#f1a900';
  let defaultSecondaryColor = queryParams.has('secondaryColor') ? '#' + queryParams.get('secondaryColor') : '#CCCCCC';
  let useSecondary = queryParams.has('secondaryColor');

  if (!data.config.theme.colors) {
    let defaultTheme = buildThemeConfigObject(defaultPrimaryColor, defaultSecondaryColor, useSecondary, defaultHighContrast, defaultDarkMode);
    data.config.theme = merge(defaultTheme, data.config.theme);
  }
  data.config.medium = data.mediumConfiguration; // XXX est-ce qu'on continue de passer par l'objet config ? Normalement la config c'est 100% pour le display, pas pour le medium, mais bon...

  // XXX Temporaire, juste pour les tests
  data.config.medium.forceRotation = queryParams.has('rotation') ? Number.parseInt(queryParams.get('rotation')) : 0;

  data.currentLocale = Object.keys(data.config.text.languages)[0];

  return data;
}


function buildThemeConfigObject(defaultPrimaryColor, defaultSecondaryColor, useSecondary, defaultHighContrast, defaultDarkMode) {
  return {
    colors: {
      palette: {
        primary: defaultPrimaryColor,
        secondary: defaultSecondaryColor,
        useSecondary: useSecondary,
        autoStyle: {
          highContrast: defaultHighContrast,
          dark: defaultDarkMode,
        },
        computed: {
          ...buildColorPalette(defaultPrimaryColor),
        }
      },
      elements: {
        ...buildTheme(buildColorPalette(defaultPrimaryColor), defaultHighContrast, defaultDarkMode)
      }
    },
    structure: {
      layout: 'base', // Le seul pour le moment, on verra plus en rajouter plus tard :)
      columns: {
        // main: ['iso', 'flag', 'country', 'currency', 'sell-price', 'sell-rate', 'buy-price', 'buy-rate'], //,
        // scroll: ['iso', 'flag', 'country', 'currency', 'sell-price', 'sell-rate', 'buy-price', 'buy-rate'], //
        main: ['iso', 'flag', 'country', 'currency', 'buy-both', 'sell-both'],
        scroll: ['iso', 'flag', 'country', 'currency', 'buy-both', 'sell-both'],
      },
      displayType: {
        buyAndSell: {
          main: 'per-base-currency', // 'per-base-currency'
          scroll: 'per-base-currency' // 'one-base-currency-unit-equals'
        },
      },
      scrollingDirection: 'vertical',
      jumbotron: 'scroll-table', // none, all-tables (?fullscreen?),
      topBar: {
        // XXX on rajoute des colonnes ? Genre heure, date+heure, date, titre, logo ?
      }
    },
    font: {
      type: 'Josefin Sans',
      weight: {
        default: 500
        // rajouter par élément
      }
    },
    sizes: {
      global: '24px',
      relativeMainTable: '1em',
      relativeMainFlags: '2em',
      relativeScrollFlags: '2em',
      relativeTopBar: '1em',
      relativeFooter: '1em',
    },
    assets: {
      flagLibrary: 'flat',
      // logo: ''
    },
    animations: {
      jumbotron: {
        list: ['fade'], // XXX liste d'animation à ajouter ?
        // cycle: 'random' // ou bien 'in-order'
      }
    }
  };
}

const dataStructure = {
  "config": {
    "text": {
      "global": {"baseCurrencySymbol": "€"},
      "languages": {
        "en": {
          "title": "Example",
          "footer": "Net prices — Updated",
          "weBuyMain": "We buy",
          "weSellMain": "We sell",
          "weBuyScroll": "We buy",
          "weSellScroll": "We sell",
          "jumbotronMessage1": "TODAY YOU PAY<br/>99,01 EUR FOR 100 USD<br/><br/>YOU GET<br/>101,00 USD FOR 100 EUR",
          "jumbotronMessage2": "TODAY WE PAY<br/>89,05 EUR  FOR 100 USD<br/><br/>YOU GIVE<br/>112,30 USD FOR 100 EUR"
        },
        "fr": {
          "title": "Exemple",
          "footer": "Taux nets — Mis à jour",
          "weBuyMain": "Nous achetons",
          "weSellMain": "Nous vendons",
          "weBuyScroll": "Nous achetons",
          "weSellScroll": "Nous vendons",
          "jumbotronMessage1": "NO FEES - NO X-TRA CHARGE :)",
          "jumbotronMessage2": "NOUS PAYONS<br/>89,05 EUR POUR 100 USD<br/><br/>VOUS DONNEZ<br/>112,30 USD POUR 100 EUR"
        }
      }
    },
    "theme": {"structure": {"scrollingDirection": "vertical"}},
    "legacy": {
      "timestamp": "20220625094451",
      "msg1": "NO FEES - NO X-TRA CHARGE :)",
      "msg1_english": "TODAY YOU PAY<br/>99,01 EUR FOR 100 USD<br/><br/>YOU GET<br/>101,00 USD FOR 100 EUR",
      "msg2": "NOUS PAYONS<br/>89,05 EUR POUR 100 USD<br/><br/>VOUS DONNEZ<br/>112,30 USD POUR 100 EUR",
      "msg2_english": "TODAY WE PAY<br/>89,05 EUR  FOR 100 USD<br/><br/>YOU GIVE<br/>112,30 USD FOR 100 EUR",
      "popup_bool": true,
      "t_popup": 10000,
      "t_popup_act": 6000,
      "t_transition": 1500,
      "t_devise": 10000,
      "transition_effect_in": "bounceIn",
      "transition_effect_out": "random",
      "transition_effects_in": [],
      "transition_effects_out": [],
      "slide_direction": "left",
      "h_perso": 0,
      "titre": "Cotations du jour",
      "logo": "",
      "raison_sociale": "Yodatech",
      "f_perso": "<p>Taux nets - Net prices - Prix Nets TTC - All taxes included</p>",
      "t_scroll_down": 20000,
      "t_scroll_up": 10000,
      "t_scroll_wait_down": 1000,
      "t_scroll_wait_up": 1000,
      "fontSizes": {"title": 22, "footer": 20, "content": 22, "col_title": 22}
    },
    "timers": {"languageSwitch": 10000, "jumbotron": {"interval": 10000, "displayTime": 6000}},
    "updatedAt": null
  },
  "data": {
    "main": [{
      "countryIso": "US",
      "currencyIso": "USD",
      "countryDefaultName": "Etats Unis",
      "currencyDefaultName": "Dollar",
      "buyRate": 1.123,
      "buyPrice": 0.8904,
      "sellRate": 1.01,
      "sellPrice": 0.99,
      "base": 1
    }, {
      "countryIso": "JP",
      "currencyIso": "JPY",
      "countryDefaultName": "Japon",
      "currencyDefaultName": "Yens",
      "buyRate": 1.5129,
      "buyPrice": 0.6609,
      "sellRate": 1.3495,
      "sellPrice": 0.741,
      "base": 100
    }, {
      "countryIso": "CA",
      "currencyIso": "CAD",
      "countryDefaultName": "Canada",
      "currencyDefaultName": "Dollar",
      "buyRate": 1.443,
      "buyPrice": 0.693,
      "sellRate": 1.2771,
      "sellPrice": 0.783,
      "base": 1
    }, {
      "countryIso": "GB",
      "currencyIso": "GBP",
      "countryDefaultName": "Angleterre",
      "currencyDefaultName": "Livre Sterling",
      "buyRate": 0.9099,
      "buyPrice": 1.099,
      "sellRate": 0.8143,
      "sellPrice": 1.228,
      "base": 1
    }, {
      "countryIso": "CH",
      "currencyIso": "CHF",
      "countryDefaultName": "Suisse",
      "currencyDefaultName": "Franc",
      "buyRate": 1.0823,
      "buyPrice": 0.9239,
      "sellRate": 0.956,
      "sellPrice": 1.046,
      "base": 1
    }],
    "scroll": [{
      "countryIso": "IR",
      "currencyIso": "IRR",
      "countryDefaultName": "Iran",
      "currencyDefaultName": "Rials",
      "buyRate": 333.0,
      "buyPrice": 0.003,
      "sellRate": 40.0,
      "sellPrice": 0.025,
      "base": 1000
    }, {
      "countryIso": "AU",
      "currencyIso": "AUD",
      "countryDefaultName": "Australie",
      "currencyDefaultName": "Dollar",
      "buyRate": 1.634,
      "buyPrice": 0.6119,
      "sellRate": 1.4205,
      "sellPrice": 0.7039,
      "base": 1
    }, {
      "countryIso": "BR",
      "currencyIso": "BRL",
      "countryDefaultName": "Brésil",
      "currencyDefaultName": "Real",
      "buyRate": 5.848,
      "buyPrice": 0.1709,
      "sellRate": 4.6729,
      "sellPrice": 0.214,
      "base": 1
    }, {
      "countryIso": "CN",
      "currencyIso": "CNY",
      "countryDefaultName": "Chine",
      "currencyDefaultName": "Yuan",
      "buyRate": 8.0645,
      "buyPrice": 0.124,
      "sellRate": 6.4516,
      "sellPrice": 0.155,
      "base": 1
    }, {
      "countryIso": "HK",
      "currencyIso": "HKD",
      "countryDefaultName": "Hong Kong",
      "currencyDefaultName": "Dollar",
      "buyRate": 9.0909,
      "buyPrice": 0.11,
      "sellRate": 7.5758,
      "sellPrice": 0.1319,
      "base": 1
    }, {
      "countryIso": "RU",
      "currencyIso": "RUB",
      "countryDefaultName": "Russie",
      "currencyDefaultName": "Roubles",
      "buyRate": 1.321,
      "buyPrice": 0.757,
      "sellRate": 53.0,
      "sellPrice": 0.0188,
      "base": 100
    }, {
      "countryIso": "SA",
      "currencyIso": "SAR",
      "countryDefaultName": "Arabie Saoudite",
      "currencyDefaultName": "Riyal",
      "buyRate": 4.6296,
      "buyPrice": 0.216,
      "sellRate": 3.6364,
      "sellPrice": 0.2749,
      "base": 1
    }, {
      "countryIso": "ZA",
      "currencyIso": "ZAR",
      "countryDefaultName": "Afrique du Sud",
      "currencyDefaultName": "Rands",
      "buyRate": 0.1908,
      "buyPrice": 5.241,
      "sellRate": 0.154,
      "sellPrice": 6.4935,
      "base": 100
    }, {
      "countryIso": "AR",
      "currencyIso": "ARS",
      "countryDefaultName": "Argentine",
      "currencyDefaultName": "Pesos",
      "buyRate": 2.3419,
      "buyPrice": 0.427,
      "sellRate": 1.0384,
      "sellPrice": 0.963,
      "base": 100
    }, {
      "countryIso": "BO",
      "currencyIso": "BOB",
      "countryDefaultName": "Bolivie",
      "currencyDefaultName": "Boliviano",
      "buyRate": 8.85,
      "buyPrice": 0.1129,
      "sellRate": 6.024,
      "sellPrice": 0.166,
      "base": 1
    }, {
      "countryIso": "BG",
      "currencyIso": "BGN",
      "countryDefaultName": "Bulgarie",
      "currencyDefaultName": "Lev",
      "buyRate": 2.1322,
      "buyPrice": 0.4689,
      "sellRate": 1.7889,
      "sellPrice": 0.559,
      "base": 1
    }, {
      "countryIso": "CL",
      "currencyIso": "CLP",
      "countryDefaultName": "Chili",
      "currencyDefaultName": "Pesos",
      "buyRate": 10.0,
      "buyPrice": 0.1,
      "sellRate": 8.2645,
      "sellPrice": 0.1209,
      "base": 100
    }, {
      "countryIso": "KR",
      "currencyIso": "KRW",
      "countryDefaultName": "Corée du Sud",
      "currencyDefaultName": "Wons",
      "buyRate": 1.5152,
      "buyPrice": 0.6599,
      "sellRate": 1.2255,
      "sellPrice": 0.8159,
      "base": 1000
    }, {
      "countryIso": "HR",
      "currencyIso": "HRK",
      "countryDefaultName": "Croatie",
      "currencyDefaultName": "Kuna",
      "buyRate": 8.4034,
      "buyPrice": 0.1189,
      "sellRate": 6.8027,
      "sellPrice": 0.147,
      "base": 1
    }, {
      "countryIso": "DK",
      "currencyIso": "DKK",
      "countryDefaultName": "Danemark",
      "currencyDefaultName": "Couronne",
      "buyRate": 8.0,
      "buyPrice": 0.125,
      "sellRate": 6.9444,
      "sellPrice": 0.144,
      "base": 1
    }, {
      "countryIso": "DO",
      "currencyIso": "DOP",
      "countryDefaultName": "Dominicaine (République)",
      "currencyDefaultName": "Pesos",
      "buyRate": 0.6406,
      "buyPrice": 1.561,
      "sellRate": 0.5195,
      "sellPrice": 1.9249,
      "base": 100
    }, {
      "countryIso": "EG",
      "currencyIso": "EGP",
      "countryDefaultName": "Egypte",
      "currencyDefaultName": "Livre",
      "buyRate": 21.88,
      "buyPrice": 0.0457,
      "sellRate": 15.72,
      "sellPrice": 0.0636,
      "base": 1
    }, {
      "countryIso": "LK",
      "currencyIso": "LKR",
      "countryDefaultName": "Sri Lanka",
      "currencyDefaultName": "Roupies",
      "buyRate": 4.4643,
      "buyPrice": 0.2239,
      "sellRate": 2.9155,
      "sellPrice": 0.3429,
      "base": 100
    }, {
      "countryIso": "AE",
      "currencyIso": "AED",
      "countryDefaultName": "Emirats Arabes Unis",
      "currencyDefaultName": "Dirham",
      "buyRate": 4.2017,
      "buyPrice": 0.2379,
      "sellRate": 3.5714,
      "sellPrice": 0.28,
      "base": 1
    }, {
      "countryIso": "HU",
      "currencyIso": "HUF",
      "countryDefaultName": "Hongrie",
      "currencyDefaultName": "Forints",
      "buyRate": 4.386,
      "buyPrice": 0.2279,
      "sellRate": 3.6496,
      "sellPrice": 0.274,
      "base": 100
    }, {
      "countryIso": "IN",
      "currencyIso": "INR",
      "countryDefaultName": "Inde",
      "currencyDefaultName": "Roupies",
      "buyRate": 0.9671,
      "buyPrice": 1.034,
      "sellRate": 0.7257,
      "sellPrice": 1.3779,
      "base": 100
    }, {
      "countryIso": "id",
      "currencyIso": "IDR",
      "countryDefaultName": "Indonésie",
      "currencyDefaultName": "Rupiahs",
      "buyRate": 18.1818,
      "buyPrice": 0.055,
      "sellRate": 13.8889,
      "sellPrice": 0.0719,
      "base": 1000
    }, {
      "countryIso": "IL",
      "currencyIso": "ILS",
      "countryDefaultName": "Israël",
      "currencyDefaultName": "Sheqel",
      "buyRate": 4.0,
      "buyPrice": 0.25,
      "sellRate": 3.367,
      "sellPrice": 0.297,
      "base": 1
    }, {
      "countryIso": "MY",
      "currencyIso": "MYR",
      "countryDefaultName": "Malaisie",
      "currencyDefaultName": "Ringgit",
      "buyRate": 5.2356,
      "buyPrice": 0.191,
      "sellRate": 4.1494,
      "sellPrice": 0.2409,
      "base": 1
    }, {
      "countryIso": "MU",
      "currencyIso": "MUR",
      "countryDefaultName": "Maurice (Ile)",
      "currencyDefaultName": "Roupies",
      "buyRate": 0.5556,
      "buyPrice": 1.7998,
      "sellRate": 0.4075,
      "sellPrice": 2.4539,
      "base": 100
    }, {
      "countryIso": "MX",
      "currencyIso": "MXN",
      "countryDefaultName": "Mexique",
      "currencyDefaultName": "Pesos",
      "buyRate": 0.2357,
      "buyPrice": 4.2426,
      "sellRate": 0.1928,
      "sellPrice": 5.1867,
      "base": 100
    }, {
      "countryIso": "NZ",
      "currencyIso": "NZD",
      "countryDefaultName": "Nouvelle Zélande",
      "currencyDefaultName": "Dollar",
      "buyRate": 1.8215,
      "buyPrice": 0.5489,
      "sellRate": 1.5528,
      "sellPrice": 0.6439,
      "base": 1
    }, {
      "countryIso": "NO",
      "currencyIso": "NOK",
      "countryDefaultName": "Norvège",
      "currencyDefaultName": "Couronne",
      "buyRate": 11.21,
      "buyPrice": 0.0892,
      "sellRate": 9.74,
      "sellPrice": 0.1026,
      "base": 1
    }, {
      "countryIso": "PE",
      "currencyIso": "PEN",
      "countryDefaultName": "Pérou",
      "currencyDefaultName": "Nuevo Sol",
      "buyRate": 4.4643,
      "buyPrice": 0.2239,
      "sellRate": 3.5088,
      "sellPrice": 0.2849,
      "base": 1
    }, {
      "countryIso": "PL",
      "currencyIso": "PLN",
      "countryDefaultName": "Pologne",
      "currencyDefaultName": "Zloty",
      "buyRate": 5.102,
      "buyPrice": 0.196,
      "sellRate": 4.2919,
      "sellPrice": 0.2329,
      "base": 1
    }, {
      "countryIso": "PF",
      "currencyIso": "XPF",
      "countryDefaultName": "Polynésie Française",
      "currencyDefaultName": "Franc CFPs",
      "buyRate": 1.2707,
      "buyPrice": 0.7869,
      "sellRate": 1.0582,
      "sellPrice": 0.945,
      "base": 100
    }, {
      "countryIso": "QA",
      "currencyIso": "QAR",
      "countryDefaultName": "Qatar",
      "currencyDefaultName": "Riyal",
      "buyRate": 4.6948,
      "buyPrice": 0.213,
      "sellRate": 3.4965,
      "sellPrice": 0.286,
      "base": 1
    }, {
      "countryIso": "RO",
      "currencyIso": "RON",
      "countryDefaultName": "Roumanie",
      "currencyDefaultName": "Leu",
      "buyRate": 5.4348,
      "buyPrice": 0.1839,
      "sellRate": 4.5455,
      "sellPrice": 0.2199,
      "base": 1
    }, {
      "countryIso": "SG",
      "currencyIso": "SGD",
      "countryDefaultName": "Singapour",
      "currencyDefaultName": "Dollar",
      "buyRate": 1.61,
      "buyPrice": 0.6211,
      "sellRate": 1.355,
      "sellPrice": 0.738,
      "base": 1
    }, {
      "countryIso": "SE",
      "currencyIso": "SEK",
      "countryDefaultName": "Suède",
      "currencyDefaultName": "Couronne",
      "buyRate": 11.48,
      "buyPrice": 0.0871,
      "sellRate": 9.97,
      "sellPrice": 0.1003,
      "base": 1
    }, {
      "countryIso": "TW",
      "currencyIso": "TWD",
      "countryDefaultName": "Taïwan",
      "currencyDefaultName": "Dollars",
      "buyRate": 0.3682,
      "buyPrice": 2.7159,
      "sellRate": 0.2843,
      "sellPrice": 3.5174,
      "base": 100
    }, {
      "countryIso": "CZ",
      "currencyIso": "CZK",
      "countryDefaultName": "Tchèque (République)",
      "currencyDefaultName": "Couronnes",
      "buyRate": 0.2683,
      "buyPrice": 3.7271,
      "sellRate": 0.2285,
      "sellPrice": 4.3763,
      "base": 100
    }, {
      "countryIso": "TH",
      "currencyIso": "THB",
      "countryDefaultName": "Thaïlande",
      "currencyDefaultName": "Bahts",
      "buyRate": 0.4141,
      "buyPrice": 2.4148,
      "sellRate": 0.3413,
      "sellPrice": 2.9299,
      "base": 100
    }, {
      "countryIso": "TN",
      "currencyIso": "TND",
      "countryDefaultName": "Tunisie",
      "currencyDefaultName": "Dinar",
      "buyRate": 4.065,
      "buyPrice": 0.246,
      "sellRate": 2.8011,
      "sellPrice": 0.357,
      "base": 1
    }, {
      "countryIso": "TZ",
      "currencyIso": "TZS",
      "countryDefaultName": "Tanzanie",
      "currencyDefaultName": "Shillings",
      "buyRate": 2.6596,
      "buyPrice": 0.3759,
      "sellRate": 2.1277,
      "sellPrice": 0.4699,
      "base": 1000
    }, {
      "countryIso": "TR",
      "currencyIso": "TRY",
      "countryDefaultName": "Turquie",
      "currencyDefaultName": "Livre",
      "buyRate": 22.7273,
      "buyPrice": 0.0439,
      "sellRate": 15.625,
      "sellPrice": 0.064,
      "base": 1
    }, {
      "countryIso": "ML",
      "currencyIso": "XOF",
      "countryDefaultName": "Afrique de l'Ouest",
      "currencyDefaultName": "Franc CFAs",
      "buyRate": 7.1942,
      "buyPrice": 0.139,
      "sellRate": 5.848,
      "sellPrice": 0.1709,
      "base": 100
    }, {
      "countryIso": "IS",
      "currencyIso": "ISK",
      "countryDefaultName": "Islande",
      "currencyDefaultName": "Couronnes",
      "buyRate": 1.5456,
      "buyPrice": 0.6469,
      "sellRate": 1.2579,
      "sellPrice": 0.7949,
      "base": 100
    }, {
      "countryIso": "VN",
      "currencyIso": "VND",
      "countryDefaultName": "Vietnam",
      "currencyDefaultName": "Dongs",
      "buyRate": 27.7778,
      "buyPrice": 0.036,
      "sellRate": 20.8333,
      "sellPrice": 0.048,
      "base": 1000
    }, {
      "countryIso": "CR",
      "currencyIso": "CRC",
      "countryDefaultName": "Costa Rica",
      "currencyDefaultName": "Colons",
      "buyRate": 8.0,
      "buyPrice": 0.125,
      "sellRate": 6.2893,
      "sellPrice": 0.159,
      "base": 100
    }, {
      "countryIso": "CO",
      "currencyIso": "COP",
      "countryDefaultName": "Colombie",
      "currencyDefaultName": "Pesos",
      "buyRate": 4.5872,
      "buyPrice": 0.2179,
      "sellRate": 3.4364,
      "sellPrice": 0.291,
      "base": 1000
    }, {
      "countryIso": "SC",
      "currencyIso": "SCR",
      "countryDefaultName": "Seychelles",
      "currencyDefaultName": "Roupie",
      "buyRate": 18.594,
      "buyPrice": 0.0537,
      "sellRate": 12.279,
      "sellPrice": 0.0814,
      "base": 1
    }, {
      "countryIso": "PH",
      "currencyIso": "PHP",
      "countryDefaultName": "Philippines",
      "currencyDefaultName": "Pesos",
      "buyRate": 0.6752,
      "buyPrice": 1.481,
      "sellRate": 0.5097,
      "sellPrice": 1.9619,
      "base": 100
    }, {
      "countryIso": "KE",
      "currencyIso": "KES",
      "countryDefaultName": "Kenya",
      "currencyDefaultName": "Schillings",
      "buyRate": 1.4771,
      "buyPrice": 0.677,
      "sellRate": 1.0627,
      "sellPrice": 0.9409,
      "base": 100
    }, {
      "countryIso": "JO",
      "currencyIso": "JOD",
      "countryDefaultName": "Jordanie",
      "currencyDefaultName": "Dinar",
      "buyRate": 0.8547,
      "buyPrice": 1.17,
      "sellRate": 0.6583,
      "sellPrice": 1.519,
      "base": 1
    }, {
      "countryIso": "CM",
      "currencyIso": "XAF",
      "countryDefaultName": "Afrique Centrale",
      "currencyDefaultName": "Franc CFAs",
      "buyRate": 8.7719,
      "buyPrice": 0.114,
      "sellRate": 5.9524,
      "sellPrice": 0.1679,
      "base": 100
    }],
    "updatedAt": "2022-06-25T09:44:51"
  },
  "mediumConfiguration": {
    "legacyData": {
      "name": "OPE-02",
      "type": "external",
      "uuid": "7bbf183e-8846-4a32-b47d-ce2a6bc89231",
      "replace": null,
      "comments": null,
      "short_id": "m121t",
      "tv_model": null,
      "created_at": "2018-11-07 11:02:16",
      "model_name": null,
      "ordered_at": null,
      "os_version": null,
      "replace_at": null,
      "replaced_at": null,
      "replaced_by": null,
      "company_uuid": "21BA839F489F406CB0EB8FFC7FB6A771",
      "display_uuid": "B3479D8F7F30462E8FB02DC710E28C43",
      "agency_trigram": "OPE",
      "fully_password": null,
      "order_reference": null,
      "screen_rotation": 0,
      "tv_from_yodatech": "waiting",
      "installation_step": "prod",
      "tvbox_orientation": "waiting",
      "fully_device_id_lan": null,
      "fully_device_id_wan": null
    }, "forceOrientation": 0
  }
}

export const dataStructureExample = prepareConfigurationObject(dataStructure);
